import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import axios from "axios";
import Moment from "moment";
import {
  Paper,
  TextField,
  Box,
  LinearProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  Button,
  Modal,
  InputAdornment
} from "@mui/material";
import { MdPerson, MdAdminPanelSettings, MdEdit, MdSave, MdClose, MdNotInterested, MdSearch } from "react-icons/md";

import NrvFuncBar from "../components/FuncBar";
import NrvText from "../components/Text";
import NrvModal from "../components/Modal";

import * as authActions from "../store/actions/auth";

export default function Users(props) {
  const [page, setPage] = useState("list");
  const [pageData, setPageData] = useState();

  if (page === "new") return <UserNewForm setPage={setPage} />;
  if (page === "details") return <UserDetails setPage={setPage} data={pageData} setData={setPageData} />;
  return <UserList setPage={setPage} setData={setPageData} />;
}

const UserList = (props) => {
  const user = useSelector((state) => state.auth);
  const breads = [{ num: 1, name: "Felhasználók kezelése" }];

  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("")
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([])

  // Handle fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("/users", {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
      setUsers(response.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, [user.accessToken]);


  // Handle filter users
  useEffect(() => {
    const handleFilterUsers = () => {
      if (filterText.length > 0) {
        const filteredArray = users.filter((user) => {
          return (
            user.name.toLowerCase().includes(filterText.toLowerCase()) ||
            user.username.toLowerCase().includes(filterText.toLowerCase())
          );
        });
        setFilteredUsers(filteredArray);
      } else {
        setFilteredUsers(users);
      }
    };
    handleFilterUsers();
  }, [filterText, users]);

  // Handle user type icon
  const UserTypeIcon = (props) => {
    if (!props.isActive) return <MdNotInterested size={30} color="red" />
    if (props.isAdmin) return <MdAdminPanelSettings size={30} color="#ffc107" />;
    return <MdPerson size={30} color="#007bff" />;
  };

  // List table
  const UserListTable = (props) => {
    return (
      <>
        <Paper className="minw-1000">
          <table className="usersListDataTable">
            <tbody>
              <tr>
                <th></th>
                <th>Felhasználónév:</th>
                <th>Név:</th>
                <th>E-mail cím:</th>
                <th>Telefonszám:</th>
              </tr>
              {props.users.map((suser, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      props.setData({ suser: suser });
                      props.setPage("details");
                    }}
                  >
                    <th>
                      <div className="iconContainer">
                        <UserTypeIcon isAdmin={suser.isAdmin} isActive={suser.isActive} />
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>{suser.username}</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>{suser.name}</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        {suser.email && <span style={{ fontWeight: "normal" }}>{suser.email}</span>}
                        {!suser.email && <span className="textQuiet">nincs megadva</span>}
                      </div>
                    </th>
                    <th>
                      <div>
                        {suser.tel && <span style={{ fontWeight: "normal" }}>{suser.tel}</span>}
                        {!suser.tel && <span className="textQuiet">nincs megadva</span>}
                      </div>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Paper>
      </>
    );
  };

  // Render
  return (
    <>
      <div className="content">
        <div className="content-top-bar">
          <NrvFuncBar.NrvFuncBarContainer breads={breads}>
            <NrvFuncBar.NrvFuncBarButton iconId="add" action={() => props.setPage("new")} tooltip="Új felhasználó hozzáadása" />
            <NrvFuncBar.NrvFuncBarSearch setText={setFilterText} text={filterText} disabled={isLoading} tooltip="Felhasználó keresés" />
          </NrvFuncBar.NrvFuncBarContainer>
        </div>
        <div className="content-main">
          {!isLoading && users.length > 0 && (
            <UserListTable users={filteredUsers} setPage={props.setPage} setData={props.setData} />
          )}
          {!isLoading && users.length === 0 && (
            <Paper className="dataCard">
              <div className="noDataCard">Nem található rögzített felhasználó.</div>
            </Paper>
          )}
          {isLoading && (
            <Paper className="dataCard">
              <div className="noDataCard">
                <CircularProgress />
              </div>
            </Paper>
          )}
        </div>
      </div>
    </>
  );
};

const UserDetails = (props) => {
  const user = useSelector((state) => state.auth);
  const [suser, setSuser] = useState(props.data.suser);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [editModalUsernameOpen, setEditModalUsernameOpen] = useState(false);
  const [editModalNameOpen, setEditModalNameOpen] = useState(false);
  const [editModalEmailOpen, setEditModalEmailOpen] = useState(false);
  const [editModalTelOpen, setEditModalTelOpen] = useState(false);
  const [editModalPermissionOpen, setEditModalPermissionOpen] = useState(false);
  const [editModalPasswordOpen, setEditModalPasswordOpen] = useState(false);
  const [editModalActiveOpen, setEditModalActiveOpen] = useState(false);

  const breads = [
    { num: 1, name: "Felhasználók kezelése" },
    { num: 2, name: suser.username },
  ];

  const handleEditSave = async (data, dialogClose) => {
    const response = await axios.patch(`/users/${suser._id}`, data, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    });
    if (!response.data.success) {
      throw new Error(response.data.data);
    }

    setSuser(response.data.data);

    if (response.data.data._id === user.userId) {
      console.log("OK");
      await dispatch(authActions.updateData({ username: response.data.data.username, name: response.data.data.name }));

      const savedUserData = JSON.parse(localStorage.getItem("userData"));

      if (savedUserData !== null) {
        const userData = {
          userName: response.data.data.username,
          rToken: savedUserData.rToken,
        };
        localStorage.setItem("userData", JSON.stringify(userData));
      }
    }

    dialogClose();
    enqueueSnackbar(response.data.message, { variant: "success" });
  };

  const EditModalUsername = () => {
    const [editedUsername, setEditedUsername] = useState(suser.username);
    const data = { username: editedUsername };
    const [dialogLoading, setDialogLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
      const resetForm = () => {
        setError();
        setEditedUsername(suser.username);
        setDialogLoading(false);
      };
      resetForm();
    }, []);

    const dialogClose = () => {
      setEditModalUsernameOpen(false);
    };

    const validateInput = async () => {
      setDialogLoading(true);

      const usernameRegex = /^[a-zA-Z0-9]+$/;

      if (!editedUsername.match(usernameRegex)) {
        setDialogLoading(false);
        return setError("A felhasználónév csak az angol abc kis és nagy betűit, illetve számokat tartalmazhat.");
      }

      if (editedUsername === suser.username) {
        return dialogClose();
      }

      if (editedUsername.length < 4) {
        setDialogLoading(false);
        return setError("A szükséges adat minimum 4 karakter!");
      } else {
        const response = await axios.get(`/users/available/${editedUsername}`, {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });
        if (!response.data.data.available) {
          setDialogLoading(false);
          return setError("A megadott felhasználónév már foglalt!");
        }
      }

      handleEditSave(data, dialogClose).catch((e) => {
        setDialogLoading(false);
        setError(e.message);
      });
    };

    return (
      <Modal open={editModalUsernameOpen}>
        <Paper className="editModal">
          <div className="editModalTitle">
            <MdEdit className="form-group-icon" />
            <span>Szerkesztés - Felhasználónév:</span>
          </div>
          {dialogLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <div className="editModalInputContainer">
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              inputProps={{ maxLength: 200 }}
              variant="outlined"
              size="small"
              style={{ width: 500 }}
              value={editedUsername}
              onChange={(e) => {
                setEditedUsername(e.target.value);
                setError();
              }}
              disabled={dialogLoading}
            />
            {error && <span className="textError">{error}</span>}
          </div>
          <div className="editModalControls">
            <Button variant="outlined" onClick={dialogClose} startIcon={<MdClose />} disabled={dialogLoading}>
              Mégsem
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                validateInput();
              }}
              startIcon={<MdSave />}
              disabled={dialogLoading}
            >
              Mentés
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const EditModalName = () => {
    const [editedName, setEditedName] = useState(suser.name);
    const data = { name: editedName };
    const [dialogLoading, setDialogLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
      const resetForm = () => {
        setError();
        setEditedName(suser.name);
        setDialogLoading(false);
      };
      resetForm();
    }, []);

    const dialogClose = () => {
      setEditModalNameOpen(false);
    };

    const validateInput = async () => {
      setDialogLoading(true);

      if (editedName === suser.name) {
        return dialogClose();
      }

      if (editedName.length < 4) {
        setDialogLoading(false);
        return setError("A szükséges adat minimum 4 karakter!");
      }

      handleEditSave(data, dialogClose).catch((e) => {
        setDialogLoading(false);
        setError(e.message);
      });
    };

    return (
      <Modal open={editModalNameOpen}>
        <Paper className="editModal">
          <div className="editModalTitle">
            <MdEdit className="form-group-icon" />
            <span>Szerkesztés - Név:</span>
          </div>
          {dialogLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <div className="editModalInputContainer">
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              inputProps={{ maxLength: 200 }}
              variant="outlined"
              size="small"
              style={{ width: 500 }}
              value={editedName}
              onChange={(e) => {
                setEditedName(e.target.value);
                setError();
              }}
              disabled={dialogLoading}
            />
            {error && <span className="textError">{error}</span>}
          </div>
          <div className="editModalControls">
            <Button variant="outlined" onClick={dialogClose} startIcon={<MdClose />} disabled={dialogLoading}>
              Mégsem
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                validateInput();
              }}
              startIcon={<MdSave />}
              disabled={dialogLoading}
            >
              Mentés
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const EditModalEmail = () => {
    const [editedEmail, setEditedEmail] = useState(suser.email);
    const data = { email: editedEmail };
    const [dialogLoading, setDialogLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
      const resetForm = () => {
        setError();
        setEditedEmail(suser.email);
        setDialogLoading(false);
      };
      resetForm();
    }, []);

    const dialogClose = () => {
      setEditModalEmailOpen(false);
    };

    const validateInput = async () => {
      setDialogLoading(true);

      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (editedEmail === suser.email) {
        return dialogClose();
      }

      if (!editedEmail.match(emailRegex)) {
        setDialogLoading(false);
        return setError("A megadott adat nem valós e-mail cím formátum!");
      }

      handleEditSave(data, dialogClose).catch((e) => {
        setDialogLoading(false);
        setError(e.message);
      });
    };

    return (
      <Modal open={editModalEmailOpen}>
        <Paper className="editModal">
          <div className="editModalTitle">
            <MdEdit className="form-group-icon" />
            <span>Szerkesztés - E-mail cím:</span>
          </div>
          {dialogLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <div className="editModalInputContainer">
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              inputProps={{ maxLength: 200 }}
              variant="outlined"
              size="small"
              style={{ width: 500 }}
              value={editedEmail}
              onChange={(e) => {
                setEditedEmail(e.target.value);
                setError();
              }}
              disabled={dialogLoading}
            />
            {error && <span className="textError">{error}</span>}
          </div>
          <div className="editModalControls">
            <Button variant="outlined" onClick={dialogClose} startIcon={<MdClose />} disabled={dialogLoading}>
              Mégsem
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                validateInput();
              }}
              startIcon={<MdSave />}
              disabled={dialogLoading}
            >
              Mentés
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const EditModalTel = () => {
    const [editedTel, setEditedTel] = useState(suser.tel);
    const data = { tel: editedTel };
    const [dialogLoading, setDialogLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
      const resetForm = () => {
        setError();
        setEditedTel(suser.tel);
        setDialogLoading(false);
      };
      resetForm();
    }, []);

    const dialogClose = () => {
      setEditModalTelOpen(false);
    };

    const validateInput = async () => {
      setDialogLoading(true);

      const telRegex = /^[0-9\-+]+$/;

      if (editedTel === suser.tel) {
        return dialogClose();
      }

      if (editedTel.length > 20) {
        setDialogLoading(false);
        return setError("A telefonszám maximum hossza 20 karakter lehet!");
      }

      if (!editedTel.match(telRegex) && editedTel !== "") {
        setDialogLoading(false);
        return setError("A megadott adat nem valós telefonszám formátum!");
      }

      handleEditSave(data, dialogClose).catch((e) => {
        setDialogLoading(false);
        setError(e.message);
      });
    };

    return (
      <Modal open={editModalTelOpen}>
        <Paper className="editModal">
          <div className="editModalTitle">
            <MdEdit className="form-group-icon" />
            <span>Szerkesztés - Telefonszám:</span>
          </div>
          {dialogLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <div className="editModalInputContainer">
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              inputProps={{ maxLength: 200 }}
              variant="outlined"
              size="small"
              style={{ width: 500 }}
              value={editedTel}
              onChange={(e) => {
                setEditedTel(e.target.value);
                setError();
              }}
              disabled={dialogLoading}
            />
            {error && <span className="textError">{error}</span>}
          </div>
          <div className="editModalControls">
            <Button variant="outlined" onClick={dialogClose} startIcon={<MdClose />} disabled={dialogLoading}>
              Mégsem
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                validateInput();
              }}
              startIcon={<MdSave />}
              disabled={dialogLoading}
            >
              Mentés
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const EditModalPermission = () => {
    const [editedPermission, setEditedPermission] = useState(suser.isAdmin ? "admin" : "user");
    const [editedIsAdmin, setEditedIsAdmin] = useState(suser.isAdmin);
    const data = { isAdmin: editedIsAdmin };
    const [dialogLoading, setDialogLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
      const resetForm = () => {
        setError();
        setEditedPermission(suser.isAdmin ? "admin" : "user");
        setDialogLoading(false);
      };
      resetForm();
    }, []);

    useEffect(() => {
      setEditedIsAdmin(editedPermission === "admin" ? true : false);
    }, [editedPermission]);

    const dialogClose = () => {
      setEditModalPermissionOpen(false);
    };

    const validateInput = async () => {
      setDialogLoading(true);

      if (editedIsAdmin === suser.isAdmin) {
        return dialogClose();
      }

      handleEditSave(data, dialogClose).catch((e) => {
        setDialogLoading(false);
        setError(e.message);
      });
    };

    return (
      <Modal open={editModalPermissionOpen}>
        <Paper className="editModal">
          <div className="editModalTitle">
            <MdEdit className="form-group-icon" />
            <span>Szerkesztés - Jogosultság:</span>
          </div>
          {dialogLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <div className="editModalInputContainer">
            <FormControl>
              <RadioGroup row value={editedPermission} onChange={(e) => setEditedPermission(e.target.value)}>
                <FormControlLabel value="user" control={<Radio />} label="Felhasználó" disabled={dialogLoading} />
                <FormControlLabel value="admin" control={<Radio />} label="Admin" disabled={dialogLoading} />
              </RadioGroup>
            </FormControl>
            {error && <span className="textError">{error}</span>}
          </div>
          <div className="editModalControls">
            <Button variant="outlined" onClick={dialogClose} startIcon={<MdClose />} disabled={dialogLoading}>
              Mégsem
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                validateInput();
              }}
              startIcon={<MdSave />}
              disabled={dialogLoading}
            >
              Mentés
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const EditModalPassword = () => {
    const [editedPassword, setEditedPassword] = useState("");
    const [editedRePassword, setEditedRePassword] = useState("");
    const data = { password: editedPassword };
    const [dialogLoading, setDialogLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
      const resetForm = () => {
        setError();
        setEditedPassword("");
        setEditedRePassword("");
        setDialogLoading(false);
      };
      resetForm();
    }, []);

    const dialogClose = () => {
      setEditModalPasswordOpen(false);
    };

    const validateInput = async () => {
      setDialogLoading(true);

      const pwRegex = new RegExp(
        "^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
        "g"
      );

      if (!pwRegex.test(editedPassword)) {
        setDialogLoading(false);
        return setError("Minimum 8 karakter, szükséges kis és nagybetű, szám és speciális karakter!");
      } else {
        if (editedPassword !== editedRePassword) {
          setDialogLoading(false);
          return setError("A jelszó és a jelszó megerősítés mező tartalma nem egyezik!");
        }
      }

      handleEditSave(data, dialogClose).catch((e) => {
        setDialogLoading(false);
        setError(e.message);
      });
    };

    return (
      <Modal open={editModalPasswordOpen}>
        <Paper className="editModal">
          <div className="editModalTitle">
            <MdEdit className="form-group-icon" />
            <span>Szerkesztés - Jogosultság:</span>
          </div>
          {dialogLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <div className="editModalInputContainer">
            <table className="editModalInputTable">
              <tbody>
                <tr>
                  <th>Jelszó:</th>
                  <th>
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      inputProps={{ maxLength: 30 }}
                      variant="outlined"
                      size="small"
                      style={{ width: 300 }}
                      value={editedPassword}
                      onChange={(e) => {
                        setEditedPassword(e.target.value);
                        setError();
                      }}
                      disabled={dialogLoading}
                      autoComplete="off"
                      type="password"
                    />
                  </th>
                </tr>
                <tr>
                  <th>Jelszó megerősítése:</th>
                  <th>
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      inputProps={{ maxLength: 30 }}
                      variant="outlined"
                      size="small"
                      style={{ width: 300 }}
                      value={editedRePassword}
                      onChange={(e) => {
                        setEditedRePassword(e.target.value);
                        setError();
                      }}
                      disabled={dialogLoading}
                      autoComplete="off"
                      type="password"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
            {error && <span className="textError">{error}</span>}
          </div>
          <div className="editModalControls">
            <Button variant="outlined" onClick={dialogClose} startIcon={<MdClose />} disabled={dialogLoading}>
              Mégsem
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                validateInput();
              }}
              startIcon={<MdSave />}
              disabled={dialogLoading}
            >
              Mentés
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const EditModalActive = () => {
    const editedIsActive = !suser.isActive;
    const data = { isActive: editedIsActive };
    const [dialogLoading, setDialogLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
      const resetForm = () => {
        setError();
        setDialogLoading(false);
      };
      resetForm();
    }, []);

    const dialogClose = () => {
      setEditModalActiveOpen(false);
    };

    const validateInput = async () => {
      setDialogLoading(true);

      handleEditSave(data, dialogClose).catch((e) => {
        setDialogLoading(false);
        setError(e.message);
      });
    };

    const DialogMessage = () => {
      if (suser.isActive) return "Biztosan letiltod a kiválasztott felhasználót?";
      return "Biztosan engedélyezed a kiválasztott felhasználót?";
    };

    const DialogSaveButtonText = () => {
      if (suser.isActive) return "Letilt";
      return "Engedélyez";
    };

    return (
      <Modal open={editModalActiveOpen}>
        <Paper className="editModal">
          <div className="editModalTitle">
            <MdEdit className="form-group-icon" />
            <span>Szerkesztés - Státusz:</span>
          </div>
          {dialogLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <div className="editModalInputContainer">
            <div>
              <DialogMessage />
            </div>
            {error && <span className="textError">{error}</span>}
          </div>
          <div className="editModalControls">
            <Button variant="outlined" onClick={dialogClose} startIcon={<MdClose />} disabled={dialogLoading}>
              Mégsem
            </Button>
            <Button
              variant="contained"
              color={editedIsActive ? "success" : "error"}

              onClick={() => {
                validateInput();
              }}
              startIcon={<MdSave />}
              disabled={dialogLoading}
            >
              <DialogSaveButtonText />
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  // Render
  return (
    <>
      <div className="content">
        <div className="content-top-bar">
          <NrvFuncBar.NrvFuncBarContainer breads={breads}>
            <NrvFuncBar.NrvFuncBarButton iconId="back" action={() => props.setPage("list")} tooltip="Vissza" />
          </NrvFuncBar.NrvFuncBarContainer>
        </div>
        <div className="content-main">
          <>
            <Paper className="padding-10">
              <table className="tableDataGrid">
                <tbody>
                  <tr className="titleRow">
                    <th colSpan={2}>
                      <div className="titleContainer">
                        <MdPerson className="titleRowIcon" />
                        <span>Felhasználói adatok</span>
                      </div>
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <MdEdit className="editIcon" onClick={() => setEditModalUsernameOpen(true)} />
                        <span>Felhasználónév:</span>
                      </div>
                    </th>
                    <th>
                      {suser.username}
                      {!suser.isActive && <span className="strong-red-text">/ Tiltva /</span>}
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <MdEdit className="editIcon" onClick={() => setEditModalNameOpen(true)} />
                        <span>Név:</span>
                      </div>
                    </th>
                    <th>{suser.name}</th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <MdEdit className="editIcon" onClick={() => setEditModalEmailOpen(true)} />
                        <span>E-mail cím:</span>
                      </div>
                    </th>
                    <th>
                      {suser.email && <span>{suser.email}</span>}
                      {!suser.email && <span className="textQuiet">nincs megadva</span>}
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <MdEdit className="editIcon" onClick={() => setEditModalTelOpen(true)} />
                        <span>Telefonszám:</span>
                      </div>
                    </th>
                    <th>
                      {suser.tel && <span>{suser.tel}</span>}
                      {!suser.tel && <span className="textQuiet">nincs megadva</span>}
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <span>Regisztrálva:</span>
                      </div>
                    </th>
                    <th>
                      {suser.createdAt && <span>{Moment(suser.createdAt).format("YYYY.MM.DD. HH:mm:ss")}</span>}
                      {!suser.createdAt && <span className="textQuiet">nem található</span>}
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <span>Utolsó belépés:</span>
                      </div>
                    </th>
                    <th>
                      {suser.lastLoginAt && <span>{Moment(suser.lastLoginAt).format("YYYY.MM.DD. HH:mm:ss")}</span>}
                      {!suser.lastLoginAt && <span className="textQuiet">nem található</span>}
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <span>Utolsó ismert IP cím:</span>
                      </div>
                    </th>
                    <th>
                      {suser.lastClientIp && <span>{suser.lastClientIp}</span>}
                      {!suser.lastClientIp && <span className="textQuiet">nem található</span>}
                    </th>
                  </tr>
                  <tr className="titleRow">
                    <th colSpan={2}>
                      <div className="titleContainer">
                        <MdAdminPanelSettings className="titleRowIcon" />
                        <span>Biztonsági adatok</span>
                      </div>
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <MdEdit className="editIcon" onClick={() => setEditModalPermissionOpen(true)} />
                        <span>Jogosultság:</span>
                      </div>
                    </th>
                    <th>
                      <span>{suser.isAdmin ? "Admin" : "Felhasználó"}</span>
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <span>Jelszó módosítás:</span>
                      </div>
                    </th>
                    <th>
                      <div className="flex">
                        <NrvFuncBar.NrvFuncBarButton
                          variant="icon-text"
                          text="Módosít"
                          iconId="key"
                          action={() => setEditModalPasswordOpen(true)}
                          tooltip="Jelszó megváltoztatása"
                          active={true}
                        />
                      </div>
                    </th>
                  </tr>
                  <tr className="dataRow">
                    <th>
                      <div className="titleContainer">
                        <span>Státusz módosítás:</span>
                      </div>
                    </th>
                    <th>
                      <div className="flex">
                        <NrvFuncBar.NrvFuncBarButton
                          variant="icon-text"
                          text={suser.isActive ? "Letiltás" : "Engedélyezés"}
                          iconId="disabled"
                          action={() => setEditModalActiveOpen(true)}
                          tooltip={suser.isActive ? "Felhasználó letiltása" : "Felhasználó engedélyezése"}
                          active={true}
                          color={suser.isActive ? "red" : "green"}
                        />
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </Paper>
            <EditModalUsername isModal={editModalUsernameOpen} />
            <EditModalName isModal={editModalNameOpen} />
            <EditModalEmail isModal={editModalEmailOpen} />
            <EditModalTel isModal={editModalTelOpen} />
            <EditModalPermission isModal={editModalPermissionOpen} />
            <EditModalPassword isModal={editModalPasswordOpen} />
            <EditModalActive isModal={editModalActiveOpen} />
          </>
        </div>
      </div>
    </>
  );
};

const UserNewForm = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.auth);
  //const telRegex = /^[0-9()-/]*$/;
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const [isLoading, setIsLoading] = useState(false);

  const [messageModalShow, setMEssageModalShow] = useState(false);
  const [messageModalTitle, setMEssageModalTitle] = useState("");
  const [messageModalText, setMessageModalText] = useState("");
  const [messageModalVariant, setMessageModalVariant] = useState("message");

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [userType, setUserType] = useState("user");
  const [isAdmin, setIsAdmin] = useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  //const [error, setError] = useState();
  const error = null
  const [incorrectFields, setIncorrectFields] = useState([]);

  const breads = [
    { num: 1, name: "Felhasználók kezelése" },
    { num: 2, name: "Új felhasználó" },
  ];

  // Handle change user type
  useEffect(() => {
    const type = userType === "admin" ? true : false;
    setIsAdmin(type);
  }, [userType]);

  // Handle message modal
  const handleMessageModalShow = (title, message, variant) => {
    setMessageModalVariant(variant);
    setMEssageModalTitle(title);
    setMessageModalText(message);
    setMEssageModalShow(true);
    setIsLoading(false);
  };

  const handleMessageModalClose = () => {
    setMEssageModalShow(false);
  };

  // Form validate
  const validateInput = async () => {
    const pwRegex = new RegExp(
      "^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
      "g"
    );
    setIsLoading(true);
    var errorFields = [];
    var errorMessages = [];

    if (username.length < 4) {
      errorFields.push("username");
      errorMessages.push("- a felhasználónév kötelező adat, minimum 4 karakter.");
    } else {
      const response = await axios.get(`/users/available/${username}`, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
      if (!response.data.data.available) {
        errorFields.push("username");
        errorMessages.push("- a megadott felhasználónév már foglalt.");
      }
    }

    if (name.length < 4) {
      errorFields.push("name");
      errorMessages.push("- a név kötelező adat, minimum 4 karakter.");
    }
    if (!email.match(emailRegex)) {
      errorFields.push("email");
      errorMessages.push("- az e-mail cím kötelező adat, valós e-mail cím formátumban.");
    }

    if (!pwRegex.test(password)) {
      errorFields.push("password");
      errorFields.push("rePassword");
      errorMessages.push(
        "- a jelszó kötelező adat, minimum 8 karakter, szükséges kis és nagybetű, szám és speciális karakter."
      );
    } else {
      if (password !== rePassword) {
        errorFields.push("password");
        errorFields.push("rePassword");
        errorMessages.push("- a jelszó és a jelszó megerősítés mező tartalma nem egyezik.");
      }
    }

    if (errorFields.length > 0) {
      handleMessageModalShow("Hibás adatbevitel", errorMessages.join("\n"), "error");
      setIncorrectFields(errorFields);
    } else {
      handleSave();
    }
  };

  // Handle save API
  const handleSave = async () => {
    const newUser = {
      username: username,
      name: name,
      email: email,
      tel: tel,
      password: password,
      isAdmin: isAdmin,
    };
    const response = await axios.post(`/users`, newUser, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    });

    if (!response.data.success) {
      handleMessageModalShow(response.data.message, response.data.data, "error");
      return;
    }
    enqueueSnackbar("Új felhasználó mentés sikeres!", { variant: "success" });
    props.setPage("list");
  };

  const removeFromIncorrectFields = (field) => {
    if (incorrectFields.includes(field)) setIncorrectFields(incorrectFields.filter((f) => f !== field));
  };

  // Render
  return (
    <>
      <div className="content">
        <div className="content-top-bar">
          <NrvFuncBar.NrvFuncBarContainer breads={breads}>
            <NrvFuncBar.NrvFuncBarButton iconId="close" action={() => props.setPage("list")} color="red" tooltip="Mégsem" />
            <NrvFuncBar.NrvFuncBarButton iconId="check" action={validateInput} color="green" tooltip="Mentés" disabled={isLoading} />
          </NrvFuncBar.NrvFuncBarContainer>
        </div>
        <div className="content-main">
          <Paper className="minw-1000">
            {isLoading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
            {error && <span className="textError">{error}</span>}
            <div className="editModalTitle">
              <MdPerson className="form-group-icon" />
              Felhasználói adatok
            </div>
            <div className="editModalInputContainer">
              <table className="editModalInputTable" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th style={{ width: 250 }}>
                      Felhasználónév: <NrvText.NrvRequiredMarkText />
                    </th>
                    <th>
                      <TextField
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        inputProps={{ maxLength: 30 }}
                        variant="outlined"
                        size="small"
                        style={{ width: 700 }}
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value);
                          removeFromIncorrectFields("username");
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        error={incorrectFields.includes("username")}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      Teljes név: <NrvText.NrvRequiredMarkText />
                    </th>
                    <th>
                      <TextField
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        inputProps={{ maxLength: 200 }}
                        variant="outlined"
                        size="small"
                        style={{ width: 700 }}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          removeFromIncorrectFields("name");
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        error={incorrectFields.includes("name")}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      E-mail cím: <NrvText.NrvRequiredMarkText />
                    </th>
                    <th>
                      <TextField
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        inputProps={{ maxLength: 30 }}
                        variant="outlined"
                        size="small"
                        style={{ width: 700 }}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          removeFromIncorrectFields("email");
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        error={incorrectFields.includes("email")}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>Telefonszám:</th>
                    <th>
                      <TextField
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        inputProps={{ maxLength: 30 }}
                        variant="outlined"
                        size="small"
                        style={{ width: 700 }}
                        value={tel}
                        onChange={(e) => {
                          setTel(e.target.value);
                          removeFromIncorrectFields("tel");
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        error={incorrectFields.includes("tel")}
                      />
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="editModalTitle">
              <MdAdminPanelSettings className="form-group-icon" />
              Biztonsági adatok
            </div>
            <div className="editModalInputContainer">
              <table className="editModalInputTable" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th style={{ width: 250 }}>
                      Jogosultság: <NrvText.NrvRequiredMarkText />
                    </th>
                    <th>
                      <FormControl>
                        <RadioGroup row value={userType} onChange={(e) => setUserType(e.target.value)}>
                          <FormControlLabel value="user" control={<Radio />} label="Felhasználó" disabled={isLoading} />
                          <FormControlLabel value="admin" control={<Radio />} label="Admin" disabled={isLoading} />
                        </RadioGroup>
                      </FormControl>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      Jelszó: <NrvText.NrvRequiredMarkText />
                    </th>
                    <th>
                      <TextField
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        inputProps={{ maxLength: 200 }}
                        variant="outlined"
                        size="small"
                        style={{ width: 700 }}
                        value={password}
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          removeFromIncorrectFields("password");
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        error={incorrectFields.includes("password")}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      Jelszó megerősítése: <NrvText.NrvRequiredMarkText />
                    </th>
                    <th>
                      <TextField
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        inputProps={{ maxLength: 200 }}
                        variant="outlined"
                        size="small"
                        style={{ width: 700 }}
                        value={rePassword}
                        type="password"
                        onChange={(e) => {
                          setRePassword(e.target.value);
                          removeFromIncorrectFields("rePassword");
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        error={incorrectFields.includes("rePassword")}
                      />
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
        </div>
      </div>
      <NrvModal.NrvMessageModal
        show={messageModalShow}
        title={messageModalTitle}
        message={messageModalText}
        onClose={handleMessageModalClose}
        variant={messageModalVariant}
      />
    </>
  );
};
