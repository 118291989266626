import React, { useState, useEffect } from "react";
import { TextField, Switch, FormControlLabel, Button, CircularProgress } from "@mui/material";

import { useDispatch } from "react-redux";
import * as authActions from "../store/actions/auth";

import { MdLogin } from "react-icons/md";

import logoColor from "../assets/logo_color.png";

export default function LoginPage() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    document.title = "Nerva - ChrisTech Kft.";
    const savedUserData = JSON.parse(localStorage.getItem("userData"));

    const dispatchAutoLogin = async () => {
      try {
        await dispatch(
          authActions.autoLogin({
            username: savedUserData.userName,
            rtoken: savedUserData.rToken,
          })
        );
      } catch (e) {
        console.log(e.message);
        setIsLoading(false);
      }
    };

    if (savedUserData === null) {
      setIsLoading(false);
    } else {
      dispatchAutoLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    setIsLoading(true);
    setError();
    try {
      await dispatch(
        authActions.logIn({
          username: username,
          password: password,
          remember: remember,
        })
      );
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  const ButtonContent = () => {
    if (isLoading) {
      return <CircularProgress size={25} />;
    } else {
      return <>Belépés</>;
    }
  };

  return (
    <>
      <div className="loginScreen">
        <div className="loginWrapper">
          <div className="loginLogoBox">
            <img src={logoColor} alt="Logo" />
          </div>
          <form className="loginInputBox">
            <TextField
              size="small"
              id="username"
              label="Felhasználónév"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={isLoading}
              autoComplete="off"
            />
            <TextField
              size="small"
              id="password"
              label="Jelszó"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              inputProps={{
                //autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              disabled={isLoading}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={remember}
                  onChange={(e) => {
                    setRemember(e.target.checked);
                  }}
                  disabled={isLoading}
                />
              }
              label="Emlékezz rám"
            />

            <Button
              variant="contained"
              onClick={handleLogin}
              startIcon={isLoading ? null : <MdLogin />}
              disabled={isLoading}
            >
              <ButtonContent />
            </Button>
            {error && <span className="textError">{error}</span>}
          </form>
        </div>
      </div>
      <div className="versionContainer">Project Nerva - 2022</div>
    </>
  );
}
