import React, { useState } from "react";
import { Paper, Button, Modal } from "@mui/material";

import NrvSurface from "../../components/Surface";
import NrvForm from "../../components/Form";
import NrvIcons from "../../components/Icon";
import NrvProgress from "../../components/Progress";

const ArticlePricing = (props) => {

	const { article, newPricingModalOpen, setNewPricingModalOpen } = props

	const [tabValue, setTabValue] = useState("sale")



	const SupplyNewModal = (props) => {
		const [dialogLoading, setDialogLoading] = useState(false)
		const [fetching, setFetching] = useState(false)
		const [error, setError] = useState()

		const Title = () => {
			return <span>Beszerzési ár hozzáadása</span>;
		};

		return (
			<Paper className="editModal">
				<div className="editModalTitle">
					<NrvIcons.Edit className="form-group-icon" />
					<Title />
				</div>
				{(dialogLoading || fetching) ? (<NrvProgress.NrvLinearProgress />) : null}
				<div className="editModalInputContainer">
					<table className="editModalInputTable">
						<tbody>
							<tr>
								<th>Irányítószám:</th>
								<th>
									{fetching ? <NrvForm.NrvSkeleton width={200} height={40} /> : (
										<NrvForm.NrvTextField
											width={200}
											maxLength={5}
											value={""}
											onChange={(e) => { }}
											placeholder="Irányítószám"
											disabled={dialogLoading}
										/>

									)}
								</th>
							</tr>
							<tr>
								<th>Cím:</th>
								<th>
									{fetching ? <NrvForm.NrvSkeleton width={500} height={40} /> : (
										<NrvForm.NrvTextField
											width={500}
											maxLength={200}
											value={""}
											onChange={(e) => { }}
											placeholder="Cím"
											disabled={dialogLoading}
										/>
									)}
								</th>
							</tr>
						</tbody>
					</table>

					{error && <span className="textError">{error}</span>}
				</div>
				<div className="editModalControls">
					<Button variant="outlined" onClick={() => setNewPricingModalOpen(false)} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
						Mégsem
					</Button>
					<Button
						variant="contained"
						onClick={() => { }}
						startIcon={<NrvIcons.Save />}
						disabled={dialogLoading || fetching}
					>
						Mentés
					</Button>
				</div>
			</Paper>
		)
	}



	const Page = (props) => {

		switch (tabValue) {
			case "supply":
				return <PageSupply />
			default:
				return <PageSale />
		}

	}

	const PageSale = () => {

		const [editModalOpen, setEditModalOpen] = useState(false)

		const EditModal = (props) => {
			const [dialogLoading, setDialogLoading] = useState(false)
			const [fetching, setFetching] = useState(false)
			const [error, setError] = useState()

			const Title = () => {
				return <span>Értékesítési ár hozzáadása</span>;
			};

			return (
				<Modal open={editModalOpen}>
					<Paper className="editModal">
						<div className="editModalTitle">
							<NrvIcons.Edit className="form-group-icon" />
							<Title />
						</div>
						{(dialogLoading || fetching) ? (<NrvProgress.NrvLinearProgress />) : null}
						<div className="editModalInputContainer">
							<table className="editModalInputTable" style={{ width: 600 }}>
								<tbody>
									<tr>
										<th>Érvényesség kezdete:</th>
										<th>
											{fetching ? <NrvForm.NrvSkeleton width={200} height={40} /> : (
												<NrvForm.NrvTextField
													width={200}
													maxLength={5}
													value={""}
													onChange={(e) => { }}
													placeholder="Irányítószám"
													disabled={dialogLoading}
												/>

											)}
										</th>
									</tr>
									<tr>
										<th>Érvényesség vége:</th>
										<th>
											{fetching ? <NrvForm.NrvSkeleton width={500} height={40} /> : (
												<NrvForm.NrvTextField
													width={200}
													maxLength={200}
													value={""}
													onChange={(e) => { }}
													placeholder="Cím"
													disabled={dialogLoading}
												/>
											)}
										</th>
									</tr>
									<tr>
										<th>Nettó ár:</th>
										<th>
											{fetching ? <NrvForm.NrvSkeleton width={500} height={40} /> : (
												<NrvForm.NrvTextField
													width={200}
													maxLength={200}
													value={""}
													onChange={(e) => { }}
													placeholder="Cím"
													disabled={dialogLoading}
												/>
											)}
										</th>
									</tr>
									<tr>
										<th>Áfa:</th>
										<th>
											{fetching ? <NrvForm.NrvSkeleton width={500} height={40} /> : (
												<NrvForm.NrvTextField
													width={200}
													maxLength={200}
													value={""}
													onChange={(e) => { }}
													placeholder="Cím"
													disabled={dialogLoading}
												/>
											)}
										</th>
									</tr>
									<tr>
										<th>Bruttó ár:</th>
										<th>
											{fetching ? <NrvForm.NrvSkeleton width={500} height={40} /> : (
												<NrvForm.NrvTextField
													width={200}
													maxLength={200}
													value={""}
													onChange={(e) => { }}
													placeholder="Cím"
													disabled={dialogLoading}
												/>
											)}
										</th>
									</tr>
								</tbody>
							</table>

							{error && <span className="textError">{error}</span>}
						</div>
						<div className="editModalControls">
							<Button variant="outlined" onClick={() => setEditModalOpen(false)} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
								Mégsem
							</Button>
							<Button
								variant="contained"
								onClick={() => { }}
								startIcon={<NrvIcons.Save />}
								disabled={dialogLoading || fetching}
							>
								Mentés
							</Button>
						</div>
					</Paper>
				</Modal>
			)
		}


		return (
			<>
				<NrvSurface.NrvContentNoData
					title="Nem található rögzített értékesítési ár!"
					buttonTitle="Értékesítési ár hozzáadása"
					buttonAction={() => setEditModalOpen(true)}
				/>
				<EditModal />
			</>
		)
	}

	const PageSupply = () => {
		return (
			<NrvSurface.NrvContentNoData
				title="Nem található rögzített beszerzési ár!"
				buttonTitle="Beszerzési ár hozzáadása"
			/>
		)
	}

	return (
		<>

			<Paper className="padding-10">
				<NrvSurface.NrvContentTabBar
					items={[
						{ name: "Értékesítés", value: "sale" },
						{ name: "Beszerzés", value: "supply" }
					]}
					value={tabValue}
					setValue={setTabValue}
				/>


				<Page />


			</Paper>

		</>
	)
}

export default ArticlePricing;