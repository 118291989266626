import React, { useEffect, useState } from "react";
import axios from "axios";
import FileDownload from "js-file-download"
import { useSelector } from "react-redux";
import { Paper, Modal, Button } from "@mui/material";

import NrvIcons from "../../components/Icon";
import NrvFuncBar from "../../components/FuncBar";
import NrvProgress from "../../components/Progress";
import NrvTooltip from "../../components/Tooltip";

const CustomersList = (props) => {
    const user = useSelector((state) => state.auth);
    const breads = [{ num: 1, name: "Ügyféltörzs" }];
  
    const [sortField, setSortField] = useState("nameShort")
    const [sortOrder, setSortOrder] = useState("asc")
  
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [filteredCustomers, setFilteredCustomers] = useState([]);
  
    // Handle fetch data from API
    useEffect(() => {
      const fetchData = async () => {
        const response = await axios.get("/customers", {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });
        setCustomers(response.data.data);
        setIsLoading(false);
      };
      fetchData();
    }, [user.accessToken]);
  
    // Handle filter and sort customers
    useEffect(() => {
  
      const filtered = () => {
        if (filterText.length === 0) return customers;
        return customers.filter((customer) => {
          return (
            customer.nameShort.toLowerCase().includes(filterText.toLowerCase()) ||
            customer.nameFull.toLowerCase().includes(filterText.toLowerCase())
          );
        });
      }
  
      const handleSorting = (sortField, sortOrder) => {
        if (!sortField) return filtered()
        return [...filtered()].sort((a, b) => {
          return (
            a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
          );
        });
      };
  
      setFilteredCustomers(handleSorting(sortField, sortOrder));
    }, [filterText, customers, sortOrder, sortField]);
  
    // Title sort icon
    const SortIcon = (props) => {
      if (props.field !== sortField) return null;
      if (sortOrder === "asc") return <NrvIcons.ChevronDown className="sortIcon" />
      return <NrvIcons.ChevronUp className="sortIcon" />
    }
  
    // Handle change sorting
    const handleSortClick = (field) => {
      if (field === sortField) {
        sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc")
      } else {
        setSortOrder("asc")
        setSortField(field)
      }
    }
  
    // List table
    const CustomerListTable = (props) => {
      return (
        <>
          <Paper className="minw-1000">
            <table className="customersListDataTable">
              <tbody>
                <tr>
                  <th></th>
                  <th>
                    <NrvTooltip.NrvLightTooltip title="Táblázat rendezése" placement="bottom-start">
                      <div onClick={() => handleSortClick("nameShort")}>
                        <SortIcon field="nameShort" /><span>Név:</span>
                      </div>
                    </NrvTooltip.NrvLightTooltip>
                  </th>
                  <th>
                    <div>
                      <span className="noPointer">Központi cím:</span>
                    </div>
                  </th>
                </tr>
                {filteredCustomers.map((customer, index) => {
                  const primaryAddress = customer.addresses.find((address) => address.primary === true);
                  const displayPrimaryCity =
                    primaryAddress.countryCode + " - " + primaryAddress.zipCode + " " + primaryAddress.city;
                  const displayPrimaryAddress = primaryAddress.address;
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        props.setData({ customer: customer });
                        props.setPage("details");
                      }}
                    >
                      <th>
                        <div className="iconContainer">
                          {/* <CustomerTypeIcon isCustomer={customer.isCustomer} isSupplier={customer.isSupplier} /> */}
                        </div>
                      </th>
                      <th>
                        <div className="nameContainer">
                          <span>{customer.nameShort}</span>
                          <span>{customer.nameFull}</span>
                        </div>
                      </th>
                      <th>
                        <div className="addressContainer">
                          <span>{displayPrimaryCity}</span>
                          <span>{displayPrimaryAddress}</span>
                        </div>
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Paper>
        </>
      );
    };

    const excelExport = async () => {
      console.log("EXPORT")

      await axios({
        url: "/customers/export",
        method: 'GET',
        responseType: 'blob', // Important
        headers: { Authorization: `Bearer ${user.accessToken}` }
      }).then((response) => {
          FileDownload(response.data, 'test.xlsx');
      })
      
    };
  
    // Render
    return (
      <>
        <div className="content">
          <div className="content-top-bar">
            <NrvFuncBar.NrvFuncBarContainer breads={breads}>
              <NrvFuncBar.NrvFuncBarButton iconId="add" action={() => props.setPage("new")} tooltip="Új ügyfél hozzáadása" />
              <NrvFuncBar.NrvFuncBarSearch setText={setFilterText} text={filterText} disabled={isLoading} tooltip="Ügyfél keresés" />
              <NrvFuncBar.NrvFuncBarButton iconId="excel" action={() => excelExport()} tooltip="Excel exportálás" color="excel" />
            </NrvFuncBar.NrvFuncBarContainer>
          </div>
          <div className="content-main">
            {!isLoading && filteredCustomers.length > 0 && (
              <CustomerListTable setData={props.setData} setPage={props.setPage} />
            )}
            {!isLoading && customers.length === 0 && (
              <Paper className="dataCard">
                <div className="noDataCard">Nem található rögzített ügyfél.</div>
              </Paper>
            )}
            {!isLoading && customers.length > 0 && filteredCustomers.length === 0 && (
              <Paper className="dataCard">
                <div className="noDataCard" style={{ color: "#dc3545" }}>
                  Nem található a keresésnek megfelelő ügyfél.
                </div>
              </Paper>
            )}
            {isLoading && (
              <Paper className="dataCard">
                <NrvProgress.NrvLinearProgress />
              </Paper>
            )}
          </div>
        </div>
      </>
    );
  };

export default CustomersList;