import React, { useState } from "react";

import CustomersNewForm from "./CustomersNewForm";
import CustomersList from "./CustomersList";
import Customer from "./Customer";

export default function Customers() {
  const [page, setPage] = useState("list");
  const [pageData, setPageData] = useState();

  if (page === "new") return <CustomersNewForm setPage={setPage} />;
  if (page === "details") return <Customer setPage={setPage} data={pageData} setData={setPageData} />;
  if (page === "list") return <CustomersList setPage={setPage} data={pageData} setData={setPageData} />;
  return null;
}



