import React from "react";

import { LinearProgress, Modal } from "@mui/material";

import Spinner from "react-bootstrap/Spinner";

const NrvLinearProgress = (props) => <LinearProgress {...props} />

const NrvLoadingContent = (props) => {
    return (
        <div className="loading-content">
            <Spinner animation="border" variant="primary" />
        </div>
    );
}

const NrvLoadingScreen = (props) => {
    return (
        <div className="loading-screen">
            <Spinner animation="border" variant="primary" />
        </div>
    );
}

const NrvProgress = {
    NrvLinearProgress,
    NrvLoadingContent,
    NrvLoadingScreen
}

export default NrvProgress