import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Paper } from "@mui/material";

import NrvIcons from "../../components/Icon";
import NrvFuncBar from "../../components/FuncBar";
import NrvProgress from "../../components/Progress";

const ArticlesList = (props) => {
	const user = useSelector((state) => state.auth);
	const breads = [{ num: 1, name: "Cikktörzs" }];

	const [isLoading, setIsLoading] = useState(true);
	const [articles, setArticles] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [filteredArticles, setFilteredArticles] = useState([]);

	// Handle fetch data from API
	useEffect(() => {
		const fetchData = async () => {
			const response = await axios.get("/articles/list", {
				headers: { Authorization: `Bearer ${user.accessToken}` },
			});
			setArticles(response.data);
			console.log(response.data)
			setIsLoading(false);
		};
		fetchData();
	}, [user.accessToken]);

	// Handle filter articles
	useEffect(() => {
		const handleFilterArticles = () => {
			if (filterText.length > 0) {
				const filteredArray = articles.filter((article) => {
					return article.name.toLowerCase().includes(filterText.toLowerCase());
				});
				setFilteredArticles(filteredArray);
			} else {
				setFilteredArticles(articles);
			}
		};
		handleFilterArticles();
	}, [filterText, articles]);

	// Handle article type icon
	const ArticleTypeIcon = (props) => {
		if (props.type === "PRODUCT") return <NrvIcons.Widgets size={20} color="#3f51b5" />;
		if (props.type === "SERVICE") return <NrvIcons.CalendarDots size={20} color="#673ab7" />;
	};

	// List table
	const ArticleListTable = (props) => {
		return (
			<>
				<Paper className="minw-1000">
					<table className="listDataTable">
						<tbody>
							<tr>
								<th style={{ width: "40px" }}></th>
								<th style={{ width: "120px" }}>Cikkszám:</th>
								<th>Megnevezés:</th>
							</tr>
							{props.articles.map((item, index) => {
								return (
									<tr
										key={index}
										onClick={() => {
											props.setData({ id: item.id });
											props.setPage("details");
										}}
									>
										<th>
											<div className="iconContainer">
												<ArticleTypeIcon type={item.typeName} />
											</div>
										</th>
										<th>
											<div className="nameContainer">
												<span>{item.code}</span>
											</div>
										</th>
										<th>
											<div className="addressContainer">
												<span>{item.name}</span>
											</div>
										</th>

									</tr>
								);
							})}
						</tbody>
					</table>
				</Paper>
			</>
		);
	};

	// Render
	return (
		<>
			<div className="content">
				<div className="content-top-bar">
					<NrvFuncBar.NrvFuncBarContainer breads={breads}>
						<NrvFuncBar.NrvFuncBarButton iconId="add" action={() => props.setPage("new")} tooltip="Új cikk hozzáadása" />
						<NrvFuncBar.NrvFuncBarSearch setText={() => { }} text={""} disabled={isLoading} tooltip="Cikk keresés" />

					</NrvFuncBar.NrvFuncBarContainer>
				</div>
				<div className="content-main">
					{!isLoading && filteredArticles.length > 0 && (
						<ArticleListTable articles={filteredArticles} setData={props.setData} setPage={props.setPage} />
					)}
					{!isLoading && articles.length === 0 && (
						<Paper className="dataCard">
							<div className="noDataCard">Nem található rögzített cikk.</div>
						</Paper>
					)}
					{!isLoading && articles.length > 0 && filteredArticles.length === 0 && (
						<Paper className="dataCard">
							<div className="noDataCard" style={{ color: "#dc3545" }}>
								Nem található a keresésnek megfelelő cikk.
							</div>
						</Paper>
					)}
					{isLoading && (
						<Paper className="dataCard">
							<NrvProgress.NrvLinearProgress />
						</Paper>
					)}
				</div>
			</div>
		</>
	);
};

export default ArticlesList;