import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Paper } from "@mui/material";


import NrvFuncBar from "../components/FuncBar";
import NrvIcons from "../components/Icon";


export default function Settings(props) {
  const [page, setPage] = useState("");
  const [pageData, setPageData] = useState();

  const Content = () => {
    if (page === "system-documents") return <SystemDocuments setPage={setPage} />;
    if (page === "serials") return <Serials setPage={setPage} data={pageData} setData={setPageData} />;
    //if (page === "list") return <ServiceList setPage={setPage} data={pageData} setData={setPageData} />;
    return null;
  }

  return (
    <div className="content">
      <Top setPage={setPage} data={pageData} setData={setPageData} />
      <Content />
    </div>
  )

}

const Top = (props) => {
  const breads = [{ num: 1, name: "Beállítások" }];

  return (
    <div className="content-top-bar">
      <NrvFuncBar.NrvFuncBarContainer breads={breads}>
        <NrvFuncBar.NrvFuncBarMenu
          text="Rendszer"
          items={[
            { name: "Sorszámozás", onClick: () => props.setPage("serials") },
            { name: "Cikk" },
            { name: "Dokumentum sablonok", onClick: () => console.log("aa") }
          ]}
          startIcon={<NrvIcons.DatabaseFill />}
        />
      </NrvFuncBar.NrvFuncBarContainer>
    </div>
  );
}

const SystemDocuments = (props) => {

  return (

    <div>Settings</div>

  );
}

const Serials = () => {

  const user = useSelector((state) => state.auth);

  const [serials, setSerials] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("/system/serials", {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
      
      setSerials(response.data.data)
      console.log(response.data.data)
      
      //setCustomers(response.data.data);
      //setIsLoading(false);
    };
    fetchData();
  }, [user.accessToken]);

  return (
    <div className="content-main">
      <Paper className="minw-1000">
        <table className="listDataTable">
          <tbody>
            <tr>
              <th style={{ width: "40px" }}></th>
              <th style={{ width: "120px" }}>Megnevezés:</th>
              <th>Maszk:</th>
              <th>Sorszám:</th>
            </tr>
            {serials.map((item, index) => {
              return (
                <tr key={index}>
                  <th></th>
                  <th>{item.name}</th>
                  <th>{item.mask}</th>
                  <th>{item.serial}</th>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Paper>
    </div>
  )
}