import React from "react";
import { Modal, Button } from "react-bootstrap";

function NrvMessageModal(props) {
  const titleClassNames = [];

  if (props.variant === "error") titleClassNames.push('color-red')

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title className={titleClassNames.join(' ')}>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="messageModalBody">{props.message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onClose}>
          Vissza
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const NrvModal = {
    NrvMessageModal
}

export default NrvModal