import React from "react";

import {
    MdOutlineEmail,
    MdPersonOutline,
    MdPhone,
    MdEdit,
    MdSave,
    MdClose,
    MdOtherHouses,
    MdCompareArrows,
    MdLocalShipping,
    MdLocalGroceryStore,
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdFilePresent,
    MdWidgets,
    MdDateRange,
    MdAttachMoney
} from "react-icons/md";

import { FaDatabase } from "react-icons/fa";

import {
    AiFillFolderOpen,
    AiFillFolder,
    AiFillFile,
    AiOutlineFolder,
    AiOutlineFile,
    AiOutlineFileText
} from "react-icons/ai";

import { BsBoxSeam, BsCurrencyDollar } from "react-icons/bs"

import { GrNotes } from "react-icons/gr"

const EmailOutline      = (props) => <MdOutlineEmail {...props} />
const PersonOutline     = (props) => <MdPersonOutline {...props} />
const Phone             = (props) => <MdPhone {...props} />
const Edit              = (props) => <MdEdit {...props} />
const Save              = (props) => <MdSave {...props} />
const Close             = (props) => <MdClose {...props} />
const HouseFill         = (props) => <MdOtherHouses {...props} />
const ArrowsHorizontal  = (props) => <MdCompareArrows {...props} />
const TruckFill         = (props) => <MdLocalShipping {...props} />
const ShoppingCart      = (props) => <MdLocalGroceryStore {...props} />
const ChevronDown       = (props) => <MdKeyboardArrowDown {...props} />
const ChevronUp         = (props) => <MdKeyboardArrowUp {...props} />
const FileAttach        = (props) => <MdFilePresent {...props} />
const Widgets           = (props) => <MdWidgets {...props} />
const CalendarDots      = (props) => <MdDateRange {...props} />
const DatabaseFill      = (props) => <FaDatabase {...props} />
const Money             = (props) => <BsCurrencyDollar {...props} />
const Box               = (props) => <BsBoxSeam {...props} />
const FolderOpenFill    = (props) => <AiFillFolderOpen {...props} />
const FolderFill        = (props) => <AiFillFolder {...props} />
const Folder            = (props) => <AiOutlineFolder {...props} />
const FileFill          = (props) => <AiFillFile {...props} />
const File              = (props) => <AiOutlineFile {...props} />
const Note              = (props) => <GrNotes {...props} />
const FileText          = (props) => <AiOutlineFileText {...props} />

const NrvIcons = {
    EmailOutline,
    PersonOutline,
    Phone,
    Edit,
    Save,
    Close,
    HouseFill,
    ArrowsHorizontal,
    TruckFill,
    ShoppingCart,
    ChevronDown,
    ChevronUp,
    FileAttach,
    Widgets,
    CalendarDots,
    DatabaseFill,
    Money,
    Box,
    Folder,
    FolderOpenFill,
    FolderFill,
    File,
    FileFill,
    FileText,
    Note
}

export default NrvIcons