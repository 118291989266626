import React, { useRef, useState, useEffect } from "react";
import {
  MdAdd,
  MdNavigateBefore,
  MdClose,
  MdCheck,
  MdPerson,
  MdEmail,
  MdPhone,
  MdEdit,
  MdDelete,
  MdOutlineEmail,
  MdLoop,
  MdFilter1,
  MdVpnKey,
  MdNotInterested,
  MdWidgets,
  MdAttachMoney,
  MdOutlineLocalShipping,
  MdSearch
} from "react-icons/md";
import { FaRegFileExcel } from "react-icons/fa";



import NrvTooltip from "./Tooltip";
import NrvIcons from "./Icon";

function NrvFuncBarContainer(props) {
  return (
    <div className="functionBar">
      <div className="functionBarBreadcrumb">
        {props.breads ? props.breads
          .sort((a, b) => (a.id < b.id ? -1 : 1))
          .map((item, i) => {
            if (props.breads.length === i + 1)
              return (
                <span key={i} className="beardcrumb-item-last">
                  {item.name}
                </span>
              );
            return (
              <span key={i} className="beardcrumb-item">
                {item.name} /{" "}
              </span>
            );
          }) : null}
      </div>
      {!props.iconsDisabled && <div className="functionBarIcons">{props.children}</div>}
    </div>
  );
}

function NrvFuncBarButton(props) {

  const { variant, active, disabled } = props;

  function Icon(props) {
    if (props.id === "add") return <MdAdd color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "back") return <MdNavigateBefore color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "close") return <MdClose color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "check") return <MdCheck color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "person") return <MdPerson color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "phone") return <MdPhone color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "mail") return <MdEmail color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "outline_mail") return <MdOutlineEmail color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "edit") return <MdEdit color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "delete") return <MdDelete color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "loop") return <MdLoop color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "filter1") return <MdFilter1 color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "key") return <MdVpnKey color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "disabled") return <MdNotInterested color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "widgets") return <MdWidgets color={IconColor(props.color)} className="functionBarBtnIcon" />;

    if (props.id === "truck") return <MdOutlineLocalShipping color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "box") return <NrvIcons.Box color={IconColor(props.color)} className="functionBarBtnIcon" />;
    if (props.id === "money") return <NrvIcons.Money color={IconColor(props.color)} className="functionBarBtnIcon" />;

    if (props.id === "folderOpenFill") return <NrvIcons.FolderOpenFill color={IconColor(props.color)} className="functionBarBtnIcon" />;

    if (props.id === "excel") return <FaRegFileExcel color={IconColor(props.color)} className="functionBarBtnIcon" />;
  }

  function IconColor(color) {
    if (color === "green") return "#1ccb52";
    if (color === "red") return "#dc3545";
    if (color === "blue") return "#0d6efd";
    if (color === "orange") return "#ffc107";
    if (color === "excel") return "#1D6F42";
    
    return null;
  }

  const ButtonIcon = () => {
    return (
      <NrvTooltip.NrvLightTooltip title={props.tooltip} placement="bottom-start">
        <div className="functionBarButton" onClick={disabled ? null : props.action}>
          <Icon className="functionBarBtnIcon" id={props.iconId} color={props.color} />
        </div>
      </NrvTooltip.NrvLightTooltip>
    );
  };

  const ButtonIconText = () => {
    const classNames = ["functionBarButtonIconText"];
    if (active) {
      classNames.push("active");
    }
    return (
      <NrvTooltip.NrvLightTooltip title={props.tooltip} placement="bottom-start">
        <div className={classNames.join(" ")} onClick={disabled ? null : props.action} >
          <Icon className="functionBarBtnIcon" id={props.iconId} color={props.color} />
          <span>{props.text}</span>
        </div>
      </NrvTooltip.NrvLightTooltip>
    );
  };

  switch (variant) {
    case "icon":
      return <ButtonIcon />;
    case "icon-text":
      return <ButtonIconText />;
    default:
      return <ButtonIcon />;
  }
}

function NrvFuncBarSearch(props) {

  const inputRef = useRef();

  return (
    <div className="searchFieldContainer">
      <NrvTooltip.NrvLightTooltip title={props.tooltip} placement="bottom-start">
        <div className="searchFieldIconContainer">
          <MdSearch onClick={() => { inputRef.current.focus() }} />
        </div>
      </NrvTooltip.NrvLightTooltip>
      <input
        type="text"
        className="searchFieldText"
        ref={inputRef}
        placeholder="Keresés..."
        value={props.text}
        onChange={(e) => props.setText(e.target.value)}
        disabled={props.disabled}
      />
      <NrvTooltip.NrvLightTooltip title="Keresés törlése" placement="bottom-start">
        <div className="searchFieldIconContainer">
          <MdClose onClick={() => { props.setText("") }} />
        </div>
      </NrvTooltip.NrvLightTooltip>
    </div>
  );
}

const NrvFuncBarMenu = (props) => {
  const [open, setOpen] = useState(false)

  const handleClose = (e) => {
    console.log(e)
    if (!e.target.className.includes("funcBarMenuItem")) {
      if (open) return setOpen(false)
    }

    e.stopPropagation();
  }

  /*
  useEffect(() => {
    console.log(open)
    if(open === true) {
      window.addEventListener("click", handleClose);
    } else {
      window.removeEventListener("click", handleClose);
    }
  }, [open])
  */

  return (
    <div className="funcBarMenuContainer">
      <div className="funcBarMenuButton" style={{ position: "relative" }} onClick={() => { setOpen(prev => !prev) }} >
        {props.startIcon}
        <span style={{ padding: 0 }}>{props.text}</span>
        <NrvIcons.ChevronDown />
      </div>
      {open ? <div className="funcBarMenuBox">
        {props.items.map((item, index) => {
          return (
            <div
              key={index}
              className="funcBarMenuItem"
              onClick={(e) => {
                if (item.onClick) {
                  item.onClick()
                }
                setOpen(false)
                e.stopPropagation();
              }}
            >
              <span>{item.name}</span>
            </div>
          );
        })}
      </div> : null}
    </div>
  )
}

const NrvFuncBar = {
  NrvFuncBarContainer,
  NrvFuncBarButton,
  NrvFuncBarSearch,
  NrvFuncBarMenu
}

export default NrvFuncBar