import React, { useEffect, useState } from "react";
import axios from "axios";
import Moment from "moment";
import moment from "moment";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Paper, Modal, Button } from "@mui/material";

import NrvIcons from "../../components/Icon";
import NrvFuncBar from "../../components/FuncBar";
import NrvForm from "../../components/Form";
import NrvProgress from "../../components/Progress";
import NrvTooltip from "../../components/Tooltip";
import NrvText from "../../components/Text";
//import NrvUtils from "../../utils/Utils"
import NrvModal from "../../components/Modal";

import CustomerDocuments from "./CustomerDocuments";

const Customer = (props) => {

  function convertMomentDateToString (value) {

    if (!value) return "";
    if (typeof(value) !== 'object') return "";

    if (isNaN(value.toDate().getFullYear())) return "";
    if (isNaN(value.toDate().getMonth())) return "";
    if (isNaN(value.toDate().getDate())) return "";

    const strYear = value.toDate().getFullYear().toString();
    const strMonth = (value.toDate().getMonth() + 1).toString();
    const strDate = value.toDate().getDate().toString();

    return strYear + "-" + strMonth + "-" + strDate
    
}

  const telRegex = /^[0-9()-/]*$/;
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector((state) => state.auth);
  const [customer, setCustomer] = useState(props.data.customer);
  const [customerPage, setCustomerPage] = useState("main");

  const [newContactModalOpen, setNewContactModalOpen] = useState(false);
  const [newAddressModalOpen, setNewAddressModalOpen] = useState(false)

  const breads = [
    { num: 1, name: "Ügyféltörzs" },
    { num: 2, name: customer.nameShort },
  ];

  // Main
  const CustomerMainPage = (props) => {
    const [isEditModal_NameShort, setIsEditModal_NameShort] = useState(false);
    const [isEditModal_NameFull, setIsEditModal_NameFull] = useState(false);
    const [isEditModal_CustomerType, setIsEditModal_CustomerType] = useState(false);
    const [isEditModal_TaxType, setIsEditModal_TaxType] = useState(false);
    const [isEditModal_TaxNumber, setIsEditModal_TaxNumber] = useState(false);
    const [isEditModal_MothersName, setIsEditModal_MothersName] = useState(false);
    const [isEditModal_MaidenName, setIsEditModal_MaidenName] = useState(false);
    const [isEditModal_BirthPlaceDate, setIsEditModal_BirthPlaceDate] = useState(false);
    const [isEditModal_Tel, setIsEditModal_Tel] = useState(false);
    const [isEditModal_Email, setIsEditModal_Email] = useState(false);
    const [isEditModal_Comment, setIsEditModal_Comment] = useState(false);

    const dispNoData = <span className="textQuiet">nincs megadva</span>

    const CustomerTypeReadable = () => {
      if (customer.isCustomer && !customer.isSupplier) return "Vevő";
      if (!customer.isCustomer && customer.isSupplier) return "Szállító";
      if (customer.isCustomer && customer.isSupplier) return "Vevő és Szállító";
    };

    const CustomerTaxTypeReadable = () => {
      if (customer.isCompany) return "Adószámmal rendelkezik (Belföldi ÁFA alany)";
      return "Adószámmal nem rendelkezik (Természetes személy)";
    };

    const CustomerTaxNumberReadable = () => {
      if (customer.taxNumber) return customer.taxNumber;
      return <span className="textQuiet">nincs megadva</span>;
    };

    const DispMothersName = () => {
      if (["", null, undefined].includes(customer.mothersName)) return dispNoData;
      return customer.mothersName;
    }

    const DispMaidenName = () => {
      if (["", null, undefined].includes(customer.maidenName)) return dispNoData;
      return customer.maidenName;
    }

    const DispBirthPlaceDate = () => {
      let separator = ""
      let place = ""
      let date = ""
      if (["", null, undefined].includes(customer.birthPlace) && ["", null, undefined].includes(customer.birthDate)) return dispNoData;
      if (!["", null, undefined].includes(customer.birthPlace) && !["", null, undefined].includes(customer.birthDate)) separator = ", ";
      if (!["", null, undefined].includes(customer.birthPlace)) place = customer.birthPlace;
      if (!["", null, undefined].includes(customer.birthDate)) date = Moment(new Date(customer.birthDate)).format("YYYY.MM.DD.");
      return place + separator + date;
    }

    const CustomerAddressReadable = () => {
      const address = customer.addresses.find((a) => a.primary === true);
      return address.countryCode + " - " + address.zipCode + " " + address.city + ", " + address.address;
    };

    const CustomerTelReadable = () => {
      if (customer.tel) return customer.tel;
      return <span className="textQuiet">nincs megadva</span>;
    };

    const CustomerEmailReadable = () => {
      if (customer.email) return customer.email;
      return <span className="textQuiet">nincs megadva</span>;
    };

    const DispComment = () => {
      if (["", null, undefined].includes(customer.comment)) return dispNoData;
      return customer.comment;
    }

    const handleEditSave = async (data, dialogClose) => {
      const response = await axios.patch(`/customers/${customer._id}`, data, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
      if (!response.data.success) {
        throw new Error(response.data.data);
      }
      setCustomer(response.data.data);
      dialogClose();
      enqueueSnackbar(response.data.message, { variant: "success" });
    };

    const EditModalNameShort = () => {
      const [editedNameShort, setEditedNameShort] = useState(customer.nameShort);
      const data = { nameShort: editedNameShort };
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      useEffect(() => {
        const resetForm = () => {
          setError();
          setEditedNameShort(customer.nameShort);
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_NameShort(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        if (editedNameShort.length < 3) {
          setDialogLoading(false);
          return setError("A szükséges adat minimum 3 karakter!");
        }

        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      return (
        <Modal open={isEditModal_NameShort}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Ügyfél rövid név:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvTextField
                width={500}
                maxLength={30}
                value={editedNameShort}
                onChange={(e) => {
                  setEditedNameShort(e.target.value);
                  setError();
                }}
                placeholder="Ügyfél rövid név"
                disabled={dialogLoading}
              />
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalNameFull = () => {
      const [editedNameFull, setEditedNameFull] = useState(customer.nameFull);
      const data = { nameFull: editedNameFull };
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      useEffect(() => {
        const resetForm = () => {
          setError();
          setEditedNameFull(customer.nameFull);
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_NameFull(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        if (editedNameFull.length < 3) {
          setDialogLoading(false);
          return setError("A szükséges adat minimum 3 karakter!");
        }

        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      return (
        <Modal open={isEditModal_NameFull}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Ügyfél teljes név:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvTextField
                width={500}
                maxLength={200}
                value={editedNameFull}
                onChange={(e) => {
                  setEditedNameFull(e.target.value);
                  setError();
                }}
                placeholder="Ügyfél teljes név"
                disabled={dialogLoading}
              />
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalCustomerType = () => {
      const [isCustomer, setIsCustomer] = useState(customer.isCustomer);
      const [isSupplier, setIsSupplier] = useState(customer.isSupplier);
      const [error, setError] = useState();
      const OriginalValue = () => {
        if (isCustomer && !isSupplier) return "customer";
        if (!isCustomer && isSupplier) return "supplier";
        if (isCustomer && isSupplier) return "both";
      };

      const [inputValue, setinputValue] = useState(OriginalValue());

      const handleChangeInput = (e) => {
        const value = e.target.value;
        switch (value) {
          case "customer":
            setIsCustomer(true);
            setIsSupplier(false);
            break;
          case "supplier":
            setIsCustomer(false);
            setIsSupplier(true);
            break;
          case "both":
            setIsCustomer(true);
            setIsSupplier(true);
            break;
          default:
            setIsCustomer(false);
            setIsSupplier(false);
            break;
        }
        setinputValue(value);
      };

      const data = { isCustomer: isCustomer, isSupplier: isSupplier };
      const [dialogLoading, setDialogLoading] = useState(false);

      useEffect(() => {
        const resetForm = () => {
          setError();
          setIsCustomer(customer.isCustomer);
          setIsSupplier(customer.isSupplier);
          setinputValue(OriginalValue());
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_CustomerType(false);
      };

      return (
        <Modal open={isEditModal_CustomerType}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Ügyfél típus:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvRadioGroup
                value={inputValue}
                onChange={handleChangeInput}
                items={[{ title: "Vevő", value: "customer" }, { title: "Szállító", value: "supplier" }, { title: "Mindkettő", value: "both" }]}
                disabled={dialogLoading}
              />
              {error ? <span className="textError">{error}</span> : null}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setDialogLoading(true);
                  handleEditSave(data, dialogClose).catch((e) => {
                    setDialogLoading(false);
                    setError(e.message);
                  });
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalTaxType = () => {
      const [isCompany, setIsCompany] = useState(customer.isCompany);
      const [editedTaxNumber, setEditedTaxNumber] = useState(["", null, undefined].includes(customer.taxNumber) ? "" : customer.taxNumber)
      const [error, setError] = useState();
      const OriginalValue = () => {
        if (isCompany) return "company";
        return "nocompany";
      };

      const [inputValue, setinputValue] = useState(OriginalValue());

      const handleChangeInput = (e) => {
        const value = e.target.value;
        if (value === "company") setIsCompany(true);
        if (value === "nocompany") setIsCompany(false);
        setinputValue(value);
      };

      const [dialogLoading, setDialogLoading] = useState(false);
      const data = { isCompany: isCompany };
      data.taxNumber = isCompany ? editedTaxNumber : undefined;

      useEffect(() => {
        const resetForm = () => {
          setError();
          setIsCompany(customer.isCompany);
          setinputValue(OriginalValue());
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = async () => {
        setIsEditModal_TaxType(false);
      };

      const validateInput = () => {
        setDialogLoading(true);

        if (isCompany && editedTaxNumber.length < 3) {
          setDialogLoading(false);
          return setError("Az adószám kötelező adat.");
        }

        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      return (
        <Modal open={isEditModal_TaxType} closeAfterTransition={true}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Adózás:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvRadioGroup
                value={inputValue}
                direction="col"
                onChange={handleChangeInput}
                items={[{ title: "Adószámmal rendelkezik (Belföldi ÁFA alany)", value: "company" }, { title: "Adószámmal nem rendelkezik (Természetes személy)", value: "nocompany" }]}
                disabled={dialogLoading}
              />
              {isCompany ? <NrvForm.NrvTextField width={200} value={editedTaxNumber} valueSet={setEditedTaxNumber} placeholder="Adószám" /> : null}
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => validateInput()}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalTaxNumber = () => {
      const [editedTaxNumber, setEditedTaxNumber] = useState(customer.taxNumber);
      const data = { taxNumber: editedTaxNumber };
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      useEffect(() => {
        const resetForm = () => {
          setError();
          setEditedTaxNumber(customer.taxNumber);
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_TaxNumber(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        if (editedTaxNumber.length < 3) {
          setDialogLoading(false);
          return setError("Az adószám kötelező adat.");
        }

        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      return (
        <Modal open={isEditModal_TaxNumber}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Adószám:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvTextField
                width={500}
                maxLength={30}
                value={editedTaxNumber}
                onChange={(e) => {
                  setEditedTaxNumber(e.target.value);
                  setError();
                }}
                placeholder="Adószám"
                disabled={dialogLoading}
              />
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalMothersName = () => {
      const [editedMothersName, setEditedMothersName] = useState(["", null, undefined].includes(customer.mothersName) ? "" : customer.mothersName);
      const data = { mothersName: editedMothersName };
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      useEffect(() => {
        const resetForm = () => {
          setError();
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_MothersName(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      return (
        <Modal open={isEditModal_MothersName}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Anyja neve:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvTextField width={500} value={editedMothersName} valueSet={setEditedMothersName} placeholder="Anyja neve" disabled={dialogLoading} />
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalMaidenName = () => {
      const [editedMaidenName, setEditedMaidenName] = useState(["", null, undefined].includes(customer.maidenName) ? "" : customer.maidenName);
      const data = { maidenName: editedMaidenName };
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      useEffect(() => {
        const resetForm = () => {
          setError();
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_MaidenName(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };
      return (
        <Modal open={isEditModal_MaidenName}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Leánykori név:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvTextField width={500} value={editedMaidenName} valueSet={setEditedMaidenName} placeholder="Leánykori név" disabled={dialogLoading} />
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalBirthPlaceDate = () => {
      const [editedBirthPlace, setEditedBirthPlace] = useState(["", null, undefined].includes(customer.birthPlace) ? "" : customer.birthPlace);
      const [editedBirthDate, setEditedBirthDate] = useState(["", null, undefined].includes(customer.birthDate) ? null : moment(new Date(customer.birthDate)))
      const [editedBirthDateDb, setEditedBirthDateDb] = useState(customer.birthDate);
      const data = { birthPlace: editedBirthPlace, birthDate: editedBirthDateDb };
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      // Reset form
      useEffect(() => {
        const resetForm = () => {
          setError();
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      // Close modal
      const dialogClose = () => {
        setIsEditModal_BirthPlaceDate(false);
      };

      // Validate input
      const validateInput = () => {
        setDialogLoading(true);

        if (editedBirthDate !== null && (isNaN(editedBirthDate.toDate().getFullYear()) || isNaN(editedBirthDate.toDate().getMonth()) || isNaN(editedBirthDate.toDate().getDate()))) {
          setDialogLoading(false);
          return setError("A születési dátum formátuma hibás!");
        }

        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      // Render modal
      return (
        <Modal open={isEditModal_BirthPlaceDate}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Születési hely, idő:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <NrvForm.NrvTextField width={320} marginRight={10} value={editedBirthPlace} valueSet={setEditedBirthPlace} placeholder="Születési hely" />
                <NrvForm.NrvDatePicker value={editedBirthDate}
                  onChange={(value) => {
                    setEditedBirthDate(value)
                    setEditedBirthDateDb(convertMomentDateToString(value))
                  }} />
              </div>
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalTel = () => {
      const [editedTel, setEditedTel] = useState(customer.tel);
      const data = { tel: editedTel };
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      useEffect(() => {
        const resetForm = () => {
          setError();
          setEditedTel(customer.tel);
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_Tel(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        if ((!editedTel.match(telRegex) || editedTel.length < 3) && editedTel.length > 0) {
          setDialogLoading(false);
          return setError("A megadott adat nem valós telefonszám formátum!");
        }
        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      return (
        <Modal open={isEditModal_Tel}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Központi telefonszám:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvTextField
                width={500}
                maxLength={20}
                value={editedTel}
                onChange={(e) => {
                  setEditedTel(e.target.value);
                  setError();
                }}
                placeholder="Központi telefonszám"
                disabled={dialogLoading}
              />
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalEmail = () => {
      const [editedEmail, setEditedEmail] = useState(customer.email);
      const [error, setError] = useState();
      const data = { email: editedEmail };
      const [dialogLoading, setDialogLoading] = useState(false);

      useEffect(() => {
        const resetForm = () => {
          setError();
          setEditedEmail(customer.email);
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_Email(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        if ((!editedEmail.match(emailRegex) || editedEmail.length < 3) && editedEmail.length > 0) {
          setDialogLoading(false);
          return setError("A megadott cím nem valós e-mail cím formátum!");
        }

        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      return (
        <Modal open={isEditModal_Email}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Központi e-mail cím:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvTextField
                width={500}
                maxLength={50}
                value={editedEmail}
                onChange={(e) => {
                  setEditedEmail(e.target.value);
                  setError();
                }}
                placeholder="Központi e-mail cím"
                disabled={dialogLoading}
              />
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    const EditModalComment = () => {
      const [editedComment, setEditedComment] = useState(["", null, undefined].includes(customer.comment) ? "" : customer.comment);
      const data = { comment: editedComment };
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      useEffect(() => {
        const resetForm = () => {
          setError();
          setDialogLoading(false);
        };
        resetForm();
      }, []);

      const dialogClose = () => {
        setIsEditModal_Comment(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        handleEditSave(data, dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      return (
        <Modal open={isEditModal_Comment}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Szerkesztés - Megjegyzés:</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <NrvForm.NrvTextField width={500} multiline={true} rows={5} value={editedComment} valueSet={setEditedComment} disabled={dialogLoading} />
              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    // Render
    return (
      <>
        <Paper className="padding-10">
          <table className="tableDataGrid">
            <tbody>
              <tr className="titleRow">
                <th colSpan={2}>
                  <div className="titleContainer">
                    <NrvIcons.PersonOutline className="titleRowIcon" />
                    <span>Főadatok</span>
                  </div>
                </th>
              </tr>
              <tr className="dataRow">
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_NameShort(true)} />
                    <span>Rövid név:</span>
                  </div>
                </th>
                <th>{customer.nameShort}</th>
              </tr>
              <tr className="dataRow">
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_NameFull(true)} />
                    <span>Teljes név:</span>
                  </div>
                </th>
                <th>{customer.nameFull}</th>
              </tr>
              <tr className="dataRow">
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_CustomerType(true)} />
                    <span>Ügyfél típus:</span>
                  </div>
                </th>
                <th>{CustomerTypeReadable()}</th>
              </tr>
              <tr className="dataRow">
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_TaxType(true)} />
                    <span>Adózás:</span>
                  </div>
                </th>
                <th>{CustomerTaxTypeReadable()}</th>
              </tr>
              <tr className={customer.isCompany ? "dataRow" : "dataRow hidden"}>
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_TaxNumber(true)} />
                    <span>Adószám:</span>
                  </div>
                </th>
                <th>{CustomerTaxNumberReadable()}</th>
              </tr>
              <tr className={customer.isCompany ? "dataRow hidden" : "dataRow"}>
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_MothersName(true)} />
                    <span>Anyja neve:</span>
                  </div>
                </th>
                <th>{DispMothersName()}</th>
              </tr>
              <tr className={customer.isCompany ? "dataRow hidden" : "dataRow"}>
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_MaidenName(true)} />
                    <span>Leánykori név:</span>
                  </div>
                </th>
                <th>{DispMaidenName()}</th>
              </tr>
              <tr className={customer.isCompany ? "dataRow hidden" : "dataRow"}>
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_BirthPlaceDate(true)} />
                    <span>Születési hely, idő:</span>
                  </div>
                </th>
                <th>{DispBirthPlaceDate()}</th>
              </tr>
              <tr className="titleRow">
                <th colSpan={2}>
                  <div className="titleContainer">
                    <NrvIcons.EmailOutline className="titleRowIcon" />
                    <span>Cím</span>
                  </div>
                </th>
              </tr>
              <tr className="dataRow">
                <th>
                  <div className="titleContainer">
                    Elsődleges (számlázási) cím:
                  </div>
                </th>
                <th>{CustomerAddressReadable()}</th>
              </tr>
              <tr className="titleRow">
                <th colSpan={2}>
                  <div className="titleContainer">
                    <NrvIcons.Phone className="titleRowIcon" />
                    Központi kapcsolat
                  </div>
                </th>
              </tr>
              <tr className="dataRow">
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_Tel(true)} />
                    <span>Telefonszám:</span>
                  </div>
                </th>
                <th>{CustomerTelReadable()}</th>
              </tr>
              <tr className="dataRow">
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_Email(true)} />
                    <span>E-mail cím:</span>
                  </div>
                </th>
                <th>{CustomerEmailReadable()}</th>
              </tr>
              <tr className="titleRow">
                <th colSpan={2}>
                  <div className="titleContainer">
                    <NrvIcons.FileAttach className="titleRowIcon" />
                    Egyéb
                  </div>
                </th>
              </tr>
              <tr className="dataRow">
                <th>
                  <div className="titleContainer">
                    <NrvIcons.Edit className="editIcon" onClick={() => setIsEditModal_Comment(true)} />
                    <span>Megjegyzés:</span>
                  </div>
                </th>
                <th className="multiRow">{DispComment()}</th>
              </tr>
            </tbody>
          </table>
        </Paper>
        <EditModalNameShort isModal={isEditModal_NameShort} />
        <EditModalNameFull isModal={isEditModal_NameFull} />
        <EditModalCustomerType isModal={isEditModal_NameShort} />
        <EditModalTaxType isModal={isEditModal_NameShort} />
        <EditModalTaxNumber isModal={isEditModal_NameShort} />
        <EditModalMothersName />
        <EditModalMaidenName />
        <EditModalBirthPlaceDate />
        <EditModalTel isModal={isEditModal_NameShort} />
        <EditModalEmail isModal={isEditModal_NameShort} />
        <EditModalComment />
      </>
    );
  };

  // Contacts page
  const CustomerContactsPage = (props) => {
    const contacts = customer.contacts;

    const [editModalContact_Open, setEditModalContact_Open] = useState(false);
    const [editModalContact_Contact, setEditModalContact_Contact] = useState();
    const [deleteModalContact_Open, setDeleteModalContact_Open] = useState(false);
    const [deleteModalContact_Contact, setDeleteModalContact_Contact] = useState();

    // Contact card
    const ContactCard = (props) => {
      const contact = props.contact;
      const ContactCompetenceReadable = () => {
        if (contact.competence) return contact.competence;
        return <span className="textQuiet">nincs megadva</span>;
      };

      const ContactTelReadable = () => {
        if (contact.tel) return contact.tel;
        return <span className="textQuiet">nincs megadva</span>;
      };

      const ContactEmailReadable = () => {
        if (contact.email) return contact.email;
        return <span className="textQuiet">nincs megadva</span>;
      };

      const ContactCommentlReadable = () => {
        if (contact.comment) return contact.comment;
        return <span className="textQuiet">nincs megadva</span>;
      };

      return (
        <>
          <Paper key={contact._id} className="padding-10 dataCard">
            <table className="tableDataGrid">
              <tbody>
                <tr className="titleRow">
                  <th colSpan={2}>
                    <div className="controlledContainer">
                      <div className="titleContainer">
                        <NrvIcons.PersonOutline className="titleRowIcon" />
                        <span>{contact.name}</span>
                      </div>
                      <div className="controlsContainer">
                        <NrvFuncBar.NrvFuncBarButton
                          iconId="edit"
                          action={() => {
                            props.setEditModalContact_Contact(contact);
                            props.setEditModalContact_Open(true);
                          }}
                          color="blue"
                          tooltip="Szerkesztés"
                        />
                        <NrvFuncBar.NrvFuncBarButton
                          iconId="delete"
                          action={() => {
                            props.setDeleteModalContact_Contact(contact);
                            props.setDeleteModalContact_Open(true);
                          }}
                          color="red"
                          tooltip="Törlés"
                        />
                      </div>
                    </div>
                  </th>
                </tr>
                <tr className="dataRow">
                  <th>
                    <div className="titleContainer">
                      <span>Kompetencia:</span>
                    </div>
                  </th>
                  <th>{ContactCompetenceReadable()}</th>
                </tr>
                <tr className="dataRow">
                  <th>
                    <div className="titleContainer">
                      <span>Telefonszám:</span>
                    </div>
                  </th>
                  <th>{ContactTelReadable()}</th>
                </tr>
                <tr className="dataRow">
                  <th>
                    <div className="titleContainer">
                      <span>E-mail cím:</span>
                    </div>
                  </th>
                  <th>{ContactEmailReadable()}</th>
                </tr>
                <tr className="dataRow">
                  <th>
                    <div className="titleContainer">
                      <span>Megjegyzés:</span>
                    </div>
                  </th>
                  <th>{ContactCommentlReadable()}</th>
                </tr>
              </tbody>
            </table>
          </Paper>
        </>
      );
    };

    // Contact edit / new from modal
    const EditModalContact = (props) => {
      const selectedContact = props.editModalContact_Contact;
      const [dialogLoading, setDialogLoading] = useState(false);

      const [editedName, setEditedName] = useState(props.mode === "edit" ? selectedContact.name : "");
      const [editedCompetence, setEditedCompetence] = useState(props.mode === "edit" ? selectedContact.competence : "");
      const [editedTel, setEditedTel] = useState(props.mode === "edit" ? selectedContact.tel : "");
      const [editedEmail, setEditedEmail] = useState(props.mode === "edit" ? selectedContact.email : "");
      const [editedComment, setEditedComment] = useState(props.mode === "edit" ? selectedContact.comment : "");

      const data = {
        name: editedName,
        competence: editedCompetence,
        tel: editedTel,
        email: editedEmail,
        comment: editedComment,
      };
      const [error, setError] = useState();
      const dialogClose = () => {
        if (props.mode === "edit") {
          props.setEditModalContact_Contact();
        }

        props.setEditModalContact_Open(false);
      };

      const validateInput = (mode) => {
        setDialogLoading(true);
        if (editedName.length < 3) {
          setDialogLoading(false);
          return setError("A név mező kötelező adat, minimum 3 karakter.");
        }

        if (editedTel !== "" && !editedTel.match(telRegex)) {
          setDialogLoading(false);
          return setError("A megadott telefonszám nem megfelelő formátum.");
        }

        if (editedEmail !== "" && !editedEmail.match(emailRegex)) {
          setDialogLoading(false);
          return setError("A megadott e-mail cím nem megfelelő formátum.");
        }

        if (mode === "edit") {
          handleEditSave(data, dialogClose).catch((e) => {
            setDialogLoading(false);
            setError(e.message);
          });
        }
        if (mode === "new") {
          handleNewSave(data, dialogClose).catch((e) => {
            setDialogLoading(false);
            setError(e.message);
          });
        }
      };

      const handleNewSave = async (data, dialogClose) => {
        const response = await axios.post(`/contacts/${customer._id}`, data, {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });

        if (!response.data.success) {
          throw new Error(response.data.data);
        }

        setCustomer(response.data.data);
        dialogClose();
        enqueueSnackbar(response.data.message, { variant: "success" });
      };

      const handleEditSave = async (data, dialogClose) => {
        const response = await axios.patch(`/contacts/${selectedContact._id}`, data, {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });

        if (!response.data.success) {
          throw new Error(response.data.data);
        }

        setCustomer(response.data.data);
        dialogClose();
        enqueueSnackbar(response.data.message, { variant: "success" });
      };

      const Title = () => {
        if (props.mode === "new") return <span>Kapcsolat hozzáadása</span>;
        if (props.mode === "edit") return <span>Kapcsolat módosítása</span>;
        return null;
      };

      return (
        <Modal open={props.editModalContact_Open}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <Title />
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <table className="editModalInputTable">
                <tbody>
                  <tr>
                    <th>Név:</th>
                    <th>
                      <NrvForm.NrvTextField
                        width={500}
                        maxLength={30}
                        value={editedName}
                        onChange={(e) => {
                          setEditedName(e.target.value);
                          setError();
                        }}
                        placeholder="Név"
                        disabled={dialogLoading}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>Kompetencia:</th>
                    <th>
                      <NrvForm.NrvTextField
                        width={500}
                        maxLength={30}
                        value={editedCompetence}
                        onChange={(e) => {
                          setEditedCompetence(e.target.value);
                          setError();
                        }}
                        placeholder="Kompetencia"
                        disabled={dialogLoading}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>Telefonszám:</th>
                    <th>
                      <NrvForm.NrvTextField
                        width={500}
                        maxLength={30}
                        value={editedTel}
                        onChange={(e) => {
                          setEditedTel(e.target.value);
                          setError();
                        }}
                        placeholder="Telefonszám"
                        disabled={dialogLoading}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>E-mail cím:</th>
                    <th>
                      <NrvForm.NrvTextField
                        width={500}
                        maxLength={30}
                        value={editedEmail}
                        onChange={(e) => {
                          setEditedEmail(e.target.value);
                          setError();
                        }}
                        placeholder="E-mail cím"
                        disabled={dialogLoading}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>Megjegyzés:</th>
                    <th>
                      <NrvForm.NrvTextField
                        width={500}
                        maxLength={30}
                        value={editedComment}
                        onChange={(e) => {
                          setEditedComment(e.target.value);
                          setError();
                        }}
                        placeholder="Megjegyzés"
                        disabled={dialogLoading}
                      />
                    </th>
                  </tr>
                </tbody>
              </table>

              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput(props.mode);
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    // Contact delete confirm modal
    const DeleteModalContact = (props) => {
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      const dialogClose = () => {
        props.setDeleteModalContact_Open(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        handleDeleteSave(dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      const handleDeleteSave = async (dialogClose) => {
        const response = await axios.delete(`/contacts/${props.deleteModalContact_Contact._id}`, {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });
        if (!response.data.success) {
          throw new Error(response.data.data);
        }

        setCustomer(response.data.data);
        dialogClose();
        enqueueSnackbar(response.data.message, { variant: "success" });
      };

      return (
        <Modal open={props.deleteModalContact_Open}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Kapcsolat törlése</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <div>Biztosan törlöd a kiválasztott kontaktot?</div>

              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Törlés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    // Render
    if (contacts.length === 0)
      return (
        <>
          <Paper className="dataCard">
            <div className="noDataCard">Nem található az ügyfélhez rögzített kontakt.</div>
          </Paper>
          <EditModalContact
            mode="new"
            editModalContact_Open={props.newContactModalOpen}
            setEditModalContact_Open={props.setNewContactModalOpen}
            editModalContact_Contact={null}
            setEditModalContact_Contact={null}
          />
        </>
      );

    return (
      <>
        {contacts.map((contact, index) => {
          return (
            <ContactCard
              key={index}
              contact={contact}
              editModalContact_Open={editModalContact_Open}
              setEditModalContact_Open={setEditModalContact_Open}
              editModalContact_Contact={editModalContact_Contact}
              setEditModalContact_Contact={setEditModalContact_Contact}
              deleteModalContact_Open={deleteModalContact_Open}
              setDeleteModalContact_Open={setDeleteModalContact_Open}
              deleteModalContact_Contact={deleteModalContact_Contact}
              setDeleteModalContact_Contact={setDeleteModalContact_Contact}
            />
          );
        })}
        <EditModalContact
          mode="new"
          editModalContact_Open={props.newContactModalOpen}
          setEditModalContact_Open={props.setNewContactModalOpen}
          editModalContact_Contact={null}
          setEditModalContact_Contact={null}
        />
        {editModalContact_Open && editModalContact_Contact && (
          <EditModalContact
            mode="edit"
            editModalContact_Open={editModalContact_Open}
            setEditModalContact_Open={setEditModalContact_Open}
            editModalContact_Contact={editModalContact_Contact}
            setEditModalContact_Contact={setEditModalContact_Contact}
          />
        )}
        {deleteModalContact_Open && deleteModalContact_Contact && (
          <DeleteModalContact
            deleteModalContact_Open={deleteModalContact_Open}
            setDeleteModalContact_Open={setDeleteModalContact_Open}
            deleteModalContact_Contact={deleteModalContact_Contact}
            setDeleteModalContact_Contact={setDeleteModalContact_Contact}
          />
        )}
      </>
    );
  };

  // Addresses
  const CustomerAddressesPage = (props) => {
    const addresses = customer.addresses;

    const [editModalAddress_Open, setEditModalAddress_Open] = useState(false);
    const [editModalAddress_Address, setEditModalAddress_Address] = useState();
    const [deleteModalAddress_Open, setDeleteModalAddress_Open] = useState(false);
    const [deleteModalAddress_Address, setDeleteModalAddress_Address] = useState();
    const [promoteModalAddress_Open, setPromoteModalAddress_Open] = useState(false);
    const [promoteModalAddress_Address, setPromoteModalAddress_Address] = useState();

    // Address card
    const AddressCard = (props) => {
      const address = props.address;

      const Title = () => {
        if (address.primary) {
          return (
            <>
              <NrvIcons.EmailOutline className="form-group-icon" />
              Elsődleges (számlázási) cím
            </>
          );
        }
        return (
          <>
            <NrvIcons.HouseFill className="form-group-icon" />
            Telephely
          </>
        );
      };

      return (
        <>
          <Paper key={address._id} className="padding-10 dataCard">
            <table className="tableDataGrid">
              <tbody>
                <tr className="titleRow">
                  <th colSpan={2}>
                    <div className="controlledContainer">
                      <div className="titleContainer">
                        <Title />
                      </div>
                      <div className="controlsContainer">
                        {!address.primary && (
                          <NrvFuncBar.NrvFuncBarButton
                            iconId="filter1"
                            action={() => {
                              props.setPromoteModalAddress_Address(address);
                              props.setPromoteModalAddress_Open(true);
                            }}
                            color="orange"
                            tooltip="Legyen elsődleges"
                          />
                        )}
                        <NrvFuncBar.NrvFuncBarButton
                          iconId="edit"
                          action={() => {
                            props.setEditModalAddress_Address(address);
                            props.setEditModalAddress_Open(true);
                          }}
                          color="blue"
                          tooltip="Szerkesztés"
                        />
                        {!address.primary && (
                          <NrvFuncBar.NrvFuncBarButton
                            iconId="delete"
                            action={() => {
                              props.setDeleteModalAddress_Address(address);
                              props.setDeleteModalAddress_Open(true);
                            }}
                            color="red"
                            tooltip="Törlés"
                          />
                        )}
                      </div>
                    </div>
                  </th>
                </tr>
                <tr className="dataRow">
                  <th>
                    <div className="titleContainer">
                      <span>Ország:</span>
                    </div>
                  </th>
                  <th>{address.countryCode}</th>
                </tr>
                <tr className="dataRow">
                  <th>
                    <div className="titleContainer">
                      <span>Irányítószám:</span>
                    </div>
                  </th>
                  <th>{address.zipCode}</th>
                </tr>
                <tr className="dataRow">
                  <th>
                    <div className="titleContainer">
                      <span>Település:</span>
                    </div>
                  </th>
                  <th>{address.city}</th>
                </tr>
                <tr className="dataRow">
                  <th>
                    <div className="titleContainer">
                      <span>Cím:</span>
                    </div>
                  </th>
                  <th>{address.address}</th>
                </tr>
              </tbody>
            </table>
          </Paper>
        </>
      );
    };

    // Address edit / new form modal
    const EditModalAddress = (props) => {
      const selectedAddress = props.editModalAddress_Address;
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      const [editedCountryCode, setEditedCountryCode] = useState(
        props.mode === "edit" ? selectedAddress.countryCode : "HU"
      );
      const [editedZipCode, setEditedZipCode] = useState(props.mode === "edit" ? selectedAddress.zipCode : "");
      const [editedCity, setEditedCity] = useState(props.mode === "edit" ? selectedAddress.city : "");
      const [editedAddress, setEditedAddress] = useState(props.mode === "edit" ? selectedAddress.address : "");

      const [countries, setCountries] = useState([]);
      const [cities, setCities] = useState([]);

      const [fetched, setFetched] = useState(false);

      const data = {
        countryCode: editedCountryCode,
        zipCode: editedZipCode,
        city: editedCity,
        address: editedAddress,
      };

      // Handle fetch data from API
      useEffect(() => {
        async function fetchData() {
          const response = await axios.get("/utils/addressData", {
            headers: { Authorization: `Bearer ${user.accessToken}` },
          });
          if (response.data.success) {
            setCountries(response.data.data.countries);
            setCities(response.data.data.cities);
            setFetched(true);
          }
        }
        if (props.editModalAddress_Open) {
          fetchData();
        }
      }, [props.editModalAddress_Open]);

      // Handle city autocomplete
      useEffect(() => {
        const cityPref = cities.find((c) => {
          return c.code === editedZipCode;
        });
        if (cityPref) {
          setEditedCity(cityPref.name);
          setError();
        }
      }, [editedZipCode]);

      const dialogClose = () => {
        props.setEditModalAddress_Open(false);
      };

      const validateInput = (mode) => {
        setDialogLoading(true);

        if (editedCountryCode.length < 2) {
          setDialogLoading(false);
          return setError("Az ország kitöltése kötelező!");
        }

        if (editedZipCode.length < 4) {
          setDialogLoading(false);
          return setError("Az irányítószám kötelező adat, minimum 4 karakter!");
        }

        if (editedCity.length < 2) {
          setDialogLoading(false);
          return setError("A település kötelező adat, minimum 2 karakter!");
        }

        if (editedAddress.length < 3) {
          setDialogLoading(false);
          return setError("A cím kötelező adat, minimum 3 karakter!");
        }

        if (mode === "new") {
          handleNewSave(data, dialogClose).catch((e) => {
            setDialogLoading(false);
            setError(e.message);
          });
        }
        if (mode === "edit") {
          handleEditSave(data, dialogClose).catch((e) => {
            setDialogLoading(false);
            setError(e.message);
          });
        }
      };

      const handleNewSave = async (data, dialogClose) => {
        const response = await axios.post(`/addresses/${customer._id}`, data, {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });

        if (!response.data.success) {
          throw new Error(response.data.data);
        }

        setCustomer(response.data.data);
        dialogClose();
        enqueueSnackbar(response.data.message, { variant: "success" });
      };

      const handleEditSave = async (data, dialogClose) => {
        const response = await axios.patch(`/addresses/${selectedAddress._id}`, data, {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });

        if (!response.data.success) {
          throw new Error(response.data.data);
        }

        setCustomer(response.data.data);
        dialogClose();
        enqueueSnackbar(response.data.message, { variant: "success" });
      };

      const Title = () => {
        return <span>Cím hozzáadása</span>;
      };

      return (
        <Modal open={props.editModalAddress_Open}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <Title />
            </div>
            {(dialogLoading || !fetched) ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <table className="editModalInputTable">
                <tbody>
                  <tr>
                    <th>Ország:</th>
                    <th>
                      {fetched ? (
                        <NrvForm.NrvSelect
                          width={500}
                          value={editedCountryCode}
                          onChange={(e) => {
                            setEditedCountryCode(e.target.value);
                            setError();
                          }}
                          disabled={dialogLoading}
                          items={countries.map(country => ({ title: country.name, value: country.code }))}
                        />
                      ) : <NrvForm.NrvSkeleton width={500} height={40} />}
                    </th>
                  </tr>
                  <tr>
                    <th>Irányítószám:</th>
                    <th>
                      {fetched ? (
                        <NrvForm.NrvTextField
                          width={200}
                          maxLength={5}
                          value={editedZipCode}
                          onChange={(e) => {
                            setEditedZipCode(e.target.value);
                            setError();
                          }}
                          placeholder="Irányítószám"
                          disabled={dialogLoading}
                        />

                      ) : <NrvForm.NrvSkeleton width={200} height={40} />}
                    </th>
                  </tr>
                  <tr>
                    <th>Település:</th>
                    <th>
                      {fetched ? (
                        <NrvForm.NrvTextField
                          width={500}
                          maxLength={50}
                          value={editedCity}
                          onChange={(e) => {
                            setEditedCity(e.target.value);
                            setError();
                          }}
                          placeholder="Település"
                          disabled={dialogLoading}
                        />
                      ) : <NrvForm.NrvSkeleton width={500} height={40} />}
                    </th>
                  </tr>
                  <tr>
                    <th>Cím:</th>
                    <th>
                      {fetched ? (
                        <NrvForm.NrvTextField
                          width={500}
                          maxLength={200}
                          value={editedAddress}
                          onChange={(e) => {
                            setEditedAddress(e.target.value);
                            setError();
                          }}
                          placeholder="Cím"
                          disabled={dialogLoading}
                        />
                      ) : <NrvForm.NrvSkeleton width={500} height={40} />}
                    </th>
                  </tr>
                </tbody>
              </table>

              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput(props.mode);
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading || !fetched}
              >
                Mentés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    // Address delete confirm modal
    const DeleteModalAddress = (props) => {
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      const dialogClose = () => {
        props.setDeleteModalAddress_Open(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        handleDeleteSave(dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      const handleDeleteSave = async (dialogClose) => {
        const response = await axios.delete(`/addresses/${props.deleteModalAddress_Address._id}`, {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });
        if (!response.data.success) {
          throw new Error(response.data.data);
        }

        setCustomer(response.data.data);
        dialogClose();
        enqueueSnackbar(response.data.message, { variant: "success" });
      };

      return (
        <Modal open={props.deleteModalAddress_Open}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Cím törlése</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <div>Biztosan törlöd a kiválasztott címet?</div>

              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Törlés
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    // Address promote confirm modal
    const PromoteModalAddress = (props) => {
      const [dialogLoading, setDialogLoading] = useState(false);
      const [error, setError] = useState();

      const dialogClose = () => {
        props.setPromoteModalAddress_Open(false);
      };

      const validateInput = () => {
        setDialogLoading(true);
        handleDeleteSave(dialogClose).catch((e) => {
          setDialogLoading(false);
          setError(e.message);
        });
      };

      const handleDeleteSave = async (dialogClose) => {
        const response = await axios.get(`/addresses/promote/${props.promoteModalAddress_Address._id}`, {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });
        if (!response.data.success) {
          throw new Error(response.data.data);
        }

        setCustomer(response.data.data);
        dialogClose();
        enqueueSnackbar(response.data.message, { variant: "success" });
      };

      return (
        <Modal open={props.promoteModalAddress_Open}>
          <Paper className="editModal">
            <div className="editModalTitle">
              <NrvIcons.Edit className="form-group-icon" />
              <span>Elsődleges cím csere</span>
            </div>
            {dialogLoading ? (<NrvProgress.NrvLinearProgress />) : null}
            <div className="editModalInputContainer">
              <div>Biztosan ez legyen a partner elsődleges (számlázási) címe?</div>

              {error && <span className="textError">{error}</span>}
            </div>
            <div className="editModalControls">
              <Button variant="outlined" onClick={dialogClose} startIcon={<NrvIcons.Close />} disabled={dialogLoading}>
                Mégsem
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  validateInput();
                }}
                startIcon={<NrvIcons.Save />}
                disabled={dialogLoading}
              >
                Jóváhagy
              </Button>
            </div>
          </Paper>
        </Modal>
      );
    };

    // Render
    return (
      <>
        {addresses
          .sort((a, b) => Number(b.primary) - Number(a.primary))
          .map((address, index) => {
            return (
              <AddressCard
                key={index}
                address={address}
                editModalAddress_Open={editModalAddress_Open}
                setEditModalAddress_Open={setEditModalAddress_Open}
                editModalAddress_Address={editModalAddress_Address}
                setEditModalAddress_Address={setEditModalAddress_Address}
                deleteModalAddress_Open={deleteModalAddress_Open}
                setDeleteModalAddress_Open={setDeleteModalAddress_Open}
                deleteModalAddress_Address={deleteModalAddress_Address}
                setDeleteModalAddress_Address={setDeleteModalAddress_Address}
                promoteModalAddress_Open={promoteModalAddress_Open}
                setPromoteModalAddress_Open={setPromoteModalAddress_Open}
                promoteModalAddress_Address={promoteModalAddress_Address}
                setPromoteModalAddress_Address={setPromoteModalAddress_Address}
              />
            );
          })}
        <EditModalAddress
          mode="new"
          editModalAddress_Open={props.newAddressModalOpen}
          setEditModalAddress_Open={props.setNewAddressModalOpen}
          editModalAddress_Address={null}
          setEditModalAddress_Address={null}
        />
        {editModalAddress_Open && editModalAddress_Address && (
          <EditModalAddress
            mode="edit"
            editModalAddress_Open={editModalAddress_Open}
            setEditModalAddress_Open={setEditModalAddress_Open}
            editModalAddress_Address={editModalAddress_Address}
            setEditModalAddress_Address={setEditModalAddress_Address}
          />
        )}
        {deleteModalAddress_Open && deleteModalAddress_Address && (
          <DeleteModalAddress
            deleteModalAddress_Open={deleteModalAddress_Open}
            setDeleteModalAddress_Open={setDeleteModalAddress_Open}
            deleteModalAddress_Address={deleteModalAddress_Address}
            setDeleteModalAddress_Address={setDeleteModalAddress_Address}
          />
        )}
        {promoteModalAddress_Open && promoteModalAddress_Address && (
          <PromoteModalAddress
            promoteModalAddress_Open={promoteModalAddress_Open}
            setPromoteModalAddress_Open={setPromoteModalAddress_Open}
            promoteModalAddress_Address={promoteModalAddress_Address}
            setPromoteModalAddress_Address={setPromoteModalAddress_Address}
          />
        )}
      </>
    );
  };

  // --- CONTAINER ---

  // Customer page
  const CustomerPage = (props) => {
    if (customerPage === "main") return <CustomerMainPage />;
    if (customerPage === "contacts")
      return (
        <CustomerContactsPage
          newContactModalOpen={props.newContactModalOpen}
          setNewContactModalOpen={props.setNewContactModalOpen}
        />
      );
    if (customerPage === "addresses")
      return (
        <CustomerAddressesPage
          newAddressModalOpen={props.newAddressModalOpen}
          setNewAddressModalOpen={props.setNewAddressModalOpen}
        />
      );
    if (customerPage === "documents") return <CustomerDocuments />
  };

  // Function bar plus button
  const FunctionBarAddBtn = () => {
    if (customerPage === "contacts")
      return (
        <NrvFuncBar.NrvFuncBarButton
          iconId="add"
          action={() => setNewContactModalOpen(true)}
          tooltip="Új kontakt hozzáadása"
          color="blue"
        />
      );
    if (customerPage === "addresses")
      return (
        <NrvFuncBar.NrvFuncBarButton
          iconId="add"
          action={() => setNewAddressModalOpen(true)}
          tooltip="Új cím hozzáadása"
          color="blue"
        />
      );
    return null;
  };

  // Render
  return (
    <>
      <div className="content">
        <div className="content-top-bar">
          <NrvFuncBar.NrvFuncBarContainer breads={breads}>
            <NrvFuncBar.NrvFuncBarButton iconId="back" action={() => props.setPage("list")} tooltip="Vissza" />
            <NrvFuncBar.NrvFuncBarButton
              variant="icon-text"
              text="Fő adatok"
              iconId="person"
              action={() => setCustomerPage("main")}
              tooltip="Ügyfél főadatok"
              active={customerPage === "main" ? true : false}
            />
            <NrvFuncBar.NrvFuncBarButton
              variant="icon-text"
              text="Kapcsolatok"
              iconId="phone"
              action={() => setCustomerPage("contacts")}
              tooltip="Személyek, kapcsolatok"
              active={customerPage === "contacts" ? true : false}
            />
            <NrvFuncBar.NrvFuncBarButton
              variant="icon-text"
              text="Címek"
              iconId="mail"
              action={() => setCustomerPage("addresses")}
              tooltip="Telephelyek, címek"
              active={customerPage === "addresses" ? true : false}
            />
            {/* 
            <NrvFuncBar.NrvFuncBarButton
              variant="icon-text"
              text="Dokumentumok"
              iconId="folderOpenFill"
              action={() => setCustomerPage("documents")}
              tooltip="Dokumentumok"
              active={customerPage === "documents" ? true : false}
            />
            */}
            <FunctionBarAddBtn />
          </NrvFuncBar.NrvFuncBarContainer>
        </div>
        <div className="content-main">
          <CustomerPage
            newContactModalOpen={newContactModalOpen}
            setNewContactModalOpen={setNewContactModalOpen}
            newAddressModalOpen={newAddressModalOpen}
            setNewAddressModalOpen={setNewAddressModalOpen}
          />
        </div>
      </div>
    </>
  );
};

export default Customer;