import React, { useState } from "react";
import { Paper } from "@mui/material";

import NrvIcons from "../../components/Icon";

const ArticleDetail = (props) => {

	const article = props.article;

	return (
		<>
			<Paper className="padding-10">
				<table className="tableDataGrid">
					<tbody>
						<tr className="titleRow">
							<th colSpan={2}>
								<div className="titleContainer">
									<NrvIcons.Box className="titleRowIcon" />
									Cikk adatok
								</div>
							</th>
						</tr>
						<tr className="dataRow">
							<th>
								<div className="titleContainer">
									<span>Cikkszám:</span>
								</div>
							</th>
							<th>{article.code}</th>
						</tr>
						<tr className="dataRow">
							<th>
								<div className="titleContainer">
									<NrvIcons.Edit className="editIcon" onClick={() => { }} />
									<span>Cikk típus:</span>
								</div>
							</th>
							<th>{article.typeName}</th>
						</tr>
						<tr className="dataRow">
							<th>
								<div className="titleContainer">
									<NrvIcons.Edit className="editIcon" onClick={() => { }} />
									<span>Megnevezés:</span>
								</div>
							</th>
							<th>{article.name}</th>
						</tr>
						<tr className="dataRow">
							<th>
								<div className="titleContainer">
									<NrvIcons.Edit className="editIcon" onClick={() => { }} />
									<span>Nyilvántartási egység:</span>
								</div>
							</th>
							<th>{article.unitName} ({article.unitCode})</th>
						</tr>
						<tr className="dataRow">
							<th>
								<div className="titleContainer">
									<NrvIcons.Edit className="editIcon" onClick={() => { }} />
									<span>Leírás:</span>
								</div>
							</th>
							<th>{article.description}</th>
						</tr>
					</tbody>
				</table>
			</Paper>
		</>
	);
};

export default ArticleDetail;