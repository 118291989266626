import apiController from "../../controllers/apiController";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE = "UPDATE";
export const REFRESH = "REFRESH";

export const refresh = (data) => {
  return (dispatch) => {
    dispatch({ type: REFRESH, data: data });
  };
}

export const updateData = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE, data: data });
  };
}

export const authenticate = (userData) => {
  return (dispatch) => {
    dispatch({ type: LOGIN, userData: userData });
  };
};

export const logOut = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch({ type: LOGOUT });
  };
};

export const logIn = (userCredentials) => {
  return async (dispatch) => {
    const { username, password, remember } = userCredentials;

    const response = await apiController.post("/auth/login", {
      username: username,
      password: password,
    });

    if (response.status === 200) {
      if (response.data.success) {
        if (remember) {
          const userData = {
            userName: response.data.data.userName,
            rToken: response.data.data.refreshToken
          };
          localStorage.setItem("userData", JSON.stringify(userData));
        }

        dispatch(authenticate(response.data.data));
      } else {
        throw new Error(response.data.message);
      }
    } else {
      throw new Error("API error!");
    }
  };
};

export const autoLogin = (userCredentials) => {
  return async (dispatch) => {
    const { username, rtoken } = userCredentials;

    const response = await apiController.post("/auth/autologin", {
      username: username,
      rtoken: rtoken,
    });

    if (response.status === 200) {
      if (response.data.success) {
        const userData = {
          userName: response.data.data.userName,
          rToken: response.data.data.refreshToken,
        };
        localStorage.setItem("userData", JSON.stringify(userData));

        dispatch(authenticate(response.data.data));
      } else {
        throw new Error(response.data.message);
      }
    } else {
      throw new Error("API error");
    }
  };
};
