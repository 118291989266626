export const CHANGE_MENU = 'CHANGE_MENU'
export const RESET_MENU = 'RESET_MENU'

export const changeMenu = (menuName) => {
    return dispatch => {
        dispatch({ type: CHANGE_MENU, menuName: menuName });
    };
}

export const resetMenu = () => {
    return dispatch => {
        dispatch({ type: RESET_MENU });
    };
}