import React from "react";

import { Paper } from "@mui/material";

import NrvFuncBar from "../components/FuncBar";

export default function Dashboard(props) {
  const breads = [{ num: 1, name: "Vezérlőpult" }];

  return (
    <div className="content">
      <div className="content-top-bar">
        <NrvFuncBar.NrvFuncBarContainer breads={breads} iconsDisabled></NrvFuncBar.NrvFuncBarContainer>
      </div>
      <div className="content-main">
        <Paper className="dataCard">
          <div className="noDataCard">Nincsenek létrehozva vezérlőpult elemek.</div>
        </Paper>
      </div>
    </div>
  );
}
