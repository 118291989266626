import React, { useState } from "react";
import NrvIcons from "../../components/Icon";

const CustomerDocuments = (props) => {



   const Item = (props) => {

      const Icon = () => {
         switch (props.type) {
            case "FOLDER":
               return <NrvIcons.Folder className="icon" />
            case "FILE":
               return <NrvIcons.File className="icon" />
            case "NOTE":
               return <NrvIcons.FileText className="icon" />
            default:
               return null
         }
      }
      return (
         <tr className="row">
            <th className="iconContainer">
               <Icon />
            </th>
            <th>
               <span>Folder</span>
            </th>
            <th className="dateContainer">
               <span>2022.11.10</span>
            </th>
         </tr>
      )
   }


   return (
      <>
         <div className="contentContainer">
            <div className="docuListContainer">
               <table className="docuList">
                  <tbody>
                     <Item type="FOLDER" />
                     <Item type="FILE" />
                     <Item type="NOTE" />
                  </tbody>
               </table>
            </div>
         </div>
      </>
   )

}

export default CustomerDocuments;