import axios from "axios";
import { store } from "..";
import * as authActions from "../store/actions/auth";

axios.defaults.baseURL = "/api";
axios.defaults.validateStatus = () => { return true }

axios.interceptors.response.use(undefined, async (err) => {
  const error = err.response;
  const reqRoute = error.data.data.url;
  const reqMethod = error.data.data.method;
  const reqBody = error.data.data.body;

  if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
    const savedUserData = JSON.parse(localStorage.getItem("userData"));

    if (savedUserData) {
      const refreshtokenResponse = await axios.post("/auth/autologin", {
        username: savedUserData.userName,
        rtoken: savedUserData.rToken,
      });

      if (refreshtokenResponse.status === 200) {
        if (refreshtokenResponse.data.success === true) {
        
          await store.dispatch(
            authActions.refresh({
              accessToken: refreshtokenResponse.data.data.accessToken,
              refreshToken: refreshtokenResponse.data.data.refreshToken,
            })
          );

          const userData = {
            userName: savedUserData.userName,
            rToken: refreshtokenResponse.data.data.refreshToken,
          };

          localStorage.setItem("userData", JSON.stringify(userData));

          if (reqMethod === "GET") {
            const newResponse = await axios.get(reqRoute, {
              headers: { Authorization: `Bearer ${refreshtokenResponse.data.data.accessToken}` },
            });
            return newResponse;
          }
          if (reqMethod === "POST") {
            const newResponse = await axios.post(reqRoute, reqBody, {
              headers: { Authorization: `Bearer ${refreshtokenResponse.data.data.accessToken}` },
            });
            return newResponse;
          }
          if (reqMethod === "PUT") {
            const newResponse = await axios.put(reqRoute, reqBody, {
              headers: { Authorization: `Bearer ${refreshtokenResponse.data.data.accessToken}` },
            });
            return newResponse;
          }
          if (reqMethod === "DELETE") {
            const newResponse = await axios.delete(reqRoute, reqBody, {
              headers: { Authorization: `Bearer ${refreshtokenResponse.data.data.accessToken}` },
            });
            return newResponse;
          }
          if (reqMethod === "PATCH") {
            const newResponse = await axios.patch(reqRoute, reqBody, {
              headers: { Authorization: `Bearer ${refreshtokenResponse.data.data.accessToken}` },
            });
            return newResponse;
          }
        }
      }
    }

    return store.dispatch(authActions.logOut());
  }
});

export function api(method, url, token) {
  return axios({ method: method, url: url, headers: { Authorization: `Bearer ${token}` } })
}

export default axios;
