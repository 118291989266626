import React, { useEffect, useState } from "react";
//import axios from "axios";
import { useSelector } from "react-redux";
//import { useSnackbar } from "notistack";
import {
  Paper,
  TextField,
  Box,
  LinearProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  Button,
  Modal,
  InputAdornment
} from "@mui/material";
import { MdPerson, MdAdminPanelSettings, MdEdit, MdSave, MdClose, MdNotInterested, MdSearch } from "react-icons/md";

import NrvFuncBar from "../components/FuncBar";
import NrvText from "../components/Text";

export default function Services(props) {
  const [page, setPage] = useState("list");
  const [pageData, setPageData] = useState();

  if (page === "new") return <ServiceNewForm setPage={setPage} />;
  //if (page === "details") return <CustomerDetails setPage={setPage} data={pageData} setData={setPageData} />;
  if (page === "list") return <ServiceList setPage={setPage} data={pageData} setData={setPageData} />;
  return null;
}

const Top = (props) => {

  const breads = [{ num: 1, name: "Szolgáltatások" }, ...props.breads];

  return (
    <div className="content-top-bar">
      <NrvFuncBar.NrvFuncBarContainer breads={breads}>
        
        <NrvFuncBar.NrvFuncBarButton
          variant="icon-text"
          text="Szolgáltatások"
          iconId="person"
          action={() => { }}
          tooltip="Szolgáltatások"
          active={props.page === "services" ? true : false}
        />
        <NrvFuncBar.NrvFuncBarButton
          variant="icon-text"
          text="Szerződések"
          iconId="person"
          action={() => { }}
          tooltip="Szerződések"
          active={props.page === "contracts" ? true : false}
        />
        <NrvFuncBar.NrvFuncBarButton iconId="add" action={props.onClickPlus} tooltip="Új szolgáltatás hozzáadása" />
        <TextField
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
          id="textfield-filter"
          value={null}
          onChange={(e) => { }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdSearch />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" onClick={() => { }} style={{ cursor: "pointer" }}>
                <MdClose />
              </InputAdornment>
            )
          }}
          style={{ width: 300 }}
          variant="outlined"
          size="small"
          error={null}
          disabled={null}
          autoComplete="off"
        />
      </NrvFuncBar.NrvFuncBarContainer>
    </div>
  );
}

const ServiceList = (props) => {
  const user = useSelector((state) => state.auth);


  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);

  // Handle fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      //const response = await axios.get("/customers", {
      //  headers: { Authorization: `Bearer ${user.accessToken}` },
      //});
      //setCustomers(response.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, [user.accessToken]);

  // Handle filter customers
  useEffect(() => {
    handleFilterServices();
  }, [filterText, services]);

  const handleFilterServices = () => {
    if (filterText.length > 0) {
      const filteredArray = services.filter((service) => {
        return (
          service.name.toLowerCase().includes(filterText.toLowerCase())
        );
      });
      setFilteredServices(filteredArray);
    } else {
      setFilteredServices(services);
    }
  };

  // List table
  const CustomerListTable = (props) => {
    return (
      <>
        <Paper className="minw-1000">
          <table className="customersListDataTable">
            <tbody>
              <tr>
                <th></th>
                <th>Név:</th>
                <th>Cím:</th>
              </tr>
              {props.customers.map((customer, index) => {
                const primaryAddress = customer.addresses.find((address) => address.primary === true);
                const displayPrimaryCity =
                  primaryAddress.countryCode + " - " + primaryAddress.zipCode + " " + primaryAddress.city;
                const displayPrimaryAddress = primaryAddress.address;
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      props.setData({ customer: customer });
                      props.setPage("details");
                    }}
                  >
                    <th>
                      <div className="iconContainer">

                      </div>
                    </th>
                    <th>
                      <div className="nameContainer">
                        <span>{customer.nameShort}</span>
                        <span>{customer.nameFull}</span>
                      </div>
                    </th>
                    <th>
                      <div className="addressContainer">
                        <span>{displayPrimaryCity}</span>
                        <span>{displayPrimaryAddress}</span>
                      </div>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Paper>
      </>
    );
  };

  // Render
  return (
    <>
      <div className="content">
        <Top page="services" onClickPlus={() => props.setPage("new")} breads={[{ num: 2, name: "Szolgáltatások" }]}/>
        <div className="content-main">
          {!isLoading && filteredServices.length > 0 && (
            <CustomerListTable customers={filteredServices} setData={props.setData} setPage={props.setPage} />
          )}
          {!isLoading && services.length === 0 && (
            <Paper className="dataCard">
              <div className="noDataCard">Nem található rögzített ügyfél.</div>
            </Paper>
          )}
          {!isLoading && services.length > 0 && filteredServices.length === 0 && (
            <Paper className="dataCard">
              <div className="noDataCard" style={{ color: "#dc3545" }}>
                Nem található a keresésnek megfelelő ügyfél.
              </div>
            </Paper>
          )}
          {isLoading && (
            <Paper className="dataCard">
              <div className="noDataCard">
                <CircularProgress />
              </div>
            </Paper>
          )}
        </div>
      </div>
    </>
  );
};

const ServiceNewForm = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("")

  const error = null
  const [incorrectFields, setIncorrectFields] = useState([]);

  const removeFromIncorrectFields = (field) => {
    if (incorrectFields.includes(field)) setIncorrectFields(incorrectFields.filter((f) => f !== field));
  };

  const breads = [
    { num: 1, name: "Szolgáltatások" },
    { num: 2, name: "Új szolgáltatás" },
  ];

  // Render
  return (
    <>
      <div className="content">
        <div className="content-top-bar">
          <NrvFuncBar.NrvFuncBarContainer breads={breads}>
            <NrvFuncBar.NrvFuncBarButton iconId="close" action={() => props.setPage("list")} color="red" tooltip="Mégsem" />
            <NrvFuncBar.NrvFuncBarButton iconId="check" action={() => { }} color="green" tooltip="Mentés" disabled={isLoading} />
          </NrvFuncBar.NrvFuncBarContainer>
        </div>
        <div className="content-main">
          <Paper className="minw-1000">
            {isLoading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
            {error && <span className="textError">{error}</span>}
            <div className="editModalTitle">
              <MdPerson className="form-group-icon" />
              Adatok
            </div>
            <div className="editModalInputContainer">
              <table className="editModalInputTable" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th style={{ width: 250 }}>
                      Megnevezés: <NrvText.NrvRequiredMarkText />
                      <br />
                      <span className={inputName.length < 5 ? "normal-red-text" : ""}>(min. 5 karakter)</span>
                    </th>
                    <th>
                      <TextField
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        inputProps={{ maxLength: 30 }}
                        variant="outlined"
                        size="small"
                        style={{ width: 700 }}
                        value={inputName}
                        onChange={(e) => {
                          setInputName(e.target.value);
                          removeFromIncorrectFields("name");
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        error={incorrectFields.includes("name")}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      Leírás:
                      <br />({500 - inputDescription.length} karakter)
                    </th>
                    <th>
                      <TextField
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        inputProps={{ maxLength: 200 }}
                        variant="outlined"
                        size="small"
                        style={{ width: 700 }}
                        value={inputDescription}
                        onChange={(e) => {
                          setInputDescription(e.target.value);
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        multiline
                        rows={4}
                      />
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="editModalTitle">
              <MdPerson className="form-group-icon" />
              Számlázás
            </div>

            <div className="editModalTitle">
              <MdPerson className="form-group-icon" />
              Árazás
            </div>


          </Paper>
        </div>
      </div>
      {/*
      <MessageModal
        show={messageModalShow}
        title={messageModalTitle}
        message={messageModalText}
        onClose={handleMessageModalClose}
        variant={messageModalVariant}
      />
       */}
    </>
  )
}