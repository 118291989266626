import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Paper } from "@mui/material";

import NrvIcons from "../../components/Icon";
import NrvFuncBar from "../../components/FuncBar";
import NrvForm from "../../components/Form";
import NrvProgress from "../../components/Progress";
import NrvText from "../../components/Text";
import NrvModal from "../../components/Modal";

const CustomersNewForm = (props) => {
    const user = useSelector((state) => state.auth);
    const { enqueueSnackbar } = useSnackbar();
    const error = null;
    const [isLoading, setIsLoading] = useState(true);
  
    const [messageModalShow, setMEssageModalShow] = useState(false);
    const [messageModalTitle, setMEssageModalTitle] = useState("");
    const [messageModalText, setMessageModalText] = useState("");
    const [messageModalVariant, setMessageModalVariant] = useState("message");
  
    const breads = [
      { num: 1, name: "Ügyféltörzs" },
      { num: 2, name: "Új ügyfél" },
    ];
  
    const [fetched, setFetched] = useState(false);
  
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
  
    const [incorrectFields, setIncorrectFields] = useState([]);
  
    const [customerType, setCustomerType] = useState("customer");
    const [isCustomer, setIsCustomer] = useState(true);
    const [isSupplier, setIsSupplier] = useState(false);
    const [nameShort, setNameShort] = useState("");
    const [nameFull, setNameFull] = useState("");
    const [taxType, setTaxType] = useState("company");
    const [isCompany, setIsCompany] = useState(true);
    const [taxNumber, setTaxNumber] = useState("");
    const [mothersName, setMothersName] = useState("");
    const [maidenName, setMaidenName] = useState("");
    const [birthPlace, setBirthPlace] = useState("");
    const [birthDate, setBirthDate] = useState(null);
    const [birthDateDb, setBirthDateDb] = useState("");
    const [countryCode, setCountryCode] = useState("HU");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [tel, setTel] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
  
    // Handle fetch data from API
    useEffect(() => {
      async function fetchData() {
        const response = await axios.get("/utils/addressData", {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        });
        if (response.data.success) {
          setCountries(response.data.data.countries);
          setCities(response.data.data.cities);
          setFetched(true);
          setIsLoading(false);
        }
      }
  
      fetchData();
    }, []);
  
    // Handle change tax type
    useEffect(() => {
      taxType === "company" ? setIsCompany(true) : setIsCompany(false);
    }, [taxType]);
  
    // Handle change customer type
    useEffect(() => {
      switch (customerType) {
        case "customer":
          setIsCustomer(true);
          setIsSupplier(false);
          break;
        case "supplier":
          setIsCustomer(false);
          setIsSupplier(true);
          break;
        case "both":
          setIsCustomer(true);
          setIsSupplier(true);
          break;
        default:
          setIsCustomer(false);
          setIsSupplier(false);
          break;
      }
    }, [customerType]);
  
    // Handle city autocomplete
    useEffect(() => {
      const cityPref = cities.find((c) => {
        return c.code === zipCode;
      });
      if (cityPref) {
        setCity(cityPref.name);
        removeFromIncorrectFields("city");
      }
    }, [zipCode]);
  
    // Handle change birth date
    useEffect(() => {
      if (!isCompany && birthDate !== null && (!isNaN(birthDate.toDate().getFullYear()) || !isNaN(birthDate.toDate().getMonth()) || !isNaN(birthDate.toDate().getDate()))) {
        setBirthDateDb(birthDate.toDate().getFullYear().toString() + "-" + (birthDate.toDate().getMonth() + 1).toString() + "-" + birthDate.toDate().getDate().toString())
      } else {
        setBirthDateDb("")
      }
    }, [birthDate])
  
    // Form validate
    const validateInput = () => {
      var errorFields = [];
  
      if (!isCompany && birthDate !== null && (isNaN(birthDate.toDate().getFullYear()) || isNaN(birthDate.toDate().getMonth()) || isNaN(birthDate.toDate().getDate()))) errorFields.push("birthDate");
      if (nameShort.length < 3) errorFields.push("nameShort");
      if (nameFull.length < 3) errorFields.push("nameFull");
      if (isCompany && taxNumber.length < 4) errorFields.push("taxNumber");
      if (zipCode.length < 4) errorFields.push("zipCode");
      if (city.length < 2) errorFields.push("city");
      if (address.length < 3) errorFields.push("address");
  
      if (errorFields.length > 0) {
        handleMessageModalShow(
          "Hibás adatbevitel",
          "Ellenőrizze a piros kerettel jelölt mezőkben megadott adatok helyességét!",
          "error"
        );
        setIncorrectFields(errorFields);
      } else {
        handleSave();
      }
    };
  
    const removeFromIncorrectFields = (field) => {
      if (incorrectFields.includes(field)) setIncorrectFields(incorrectFields.filter((f) => f !== field));
    };
  
    // Handle save API
    const handleSave = async () => {
  
      const newCustomer = {
        isCustomer: isCustomer,
        isSupplier: isSupplier,
        isCompany: isCompany,
        nameShort: nameShort,
        nameFull: nameFull,
        countryCode: countryCode,
        zipCode: zipCode,
        city: city,
        address: address,
        taxNumber: taxNumber,
        mothersName: mothersName,
        maidenName: maidenName,
        birthPlace: birthPlace,
        birthDate: birthDateDb,
        tel: tel,
        email: email,
        comment: comment
      };
  
      const response = await axios.post("/customers", newCustomer, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
  
      if (!response.data.success) {
        handleMessageModalShow(response.data.message, response.data.data, "error");
        return;
      }
      enqueueSnackbar("Új ügyfél mentés sikeres!", { variant: "success" });
      props.setPage("list");
    };
  
    // Handle message modal
    const handleMessageModalShow = (title, message, variant) => {
      setMessageModalVariant(variant);
      setMEssageModalTitle(title);
      setMessageModalText(message);
      setMEssageModalShow(true);
      setIsLoading(false);
    };
  
    const handleMessageModalClose = () => {
      setMEssageModalShow(false);
    };
  
    // Render
    return (
      <>
        <div className="content">
          <div className="content-top-bar">
            <NrvFuncBar.NrvFuncBarContainer breads={breads}>
              <NrvFuncBar.NrvFuncBarButton iconId="close" action={() => props.setPage("list")} color="red" tooltip="Mégsem" />
              <NrvFuncBar.NrvFuncBarButton iconId="check" action={validateInput} color="green" tooltip="Mentés" disabled={isLoading} />
            </NrvFuncBar.NrvFuncBarContainer>
          </div>
          <div className="content-main">
            <Paper className="minw-1000">
              {isLoading ? (<NrvProgress.NrvLinearProgress />) : null}
              {error && <span className="textError">{error}</span>}
              <div className="editModalTitle">
                <NrvIcons.PersonOutline className="form-group-icon" />
                Főadatok
              </div>
              <div className="editModalInputContainer">
                <table className="editModalInputTable" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th style={{ width: 250 }}>Ügyfél típus: <NrvText.NrvRequiredMarkText /></th>
                      <th>
                        <NrvForm.NrvRadioGroup
                          value={customerType}
                          valueSet={setCustomerType}
                          items={[{ title: "Vevő", value: "customer" }, { title: "Szállító", value: "supplier" }, { title: "Mindkettő", value: "both" }]}
                          disabled={isLoading}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Rövid név: <NrvText.NrvRequiredMarkText /></th>
                      <th>
                        <NrvForm.NrvTextField
                          maxLength={30}
                          width={700}
                          value={nameShort}
                          onChange={(e) => {
                            setNameShort(e.target.value);
                            removeFromIncorrectFields("nameShort");
                          }}
                          disabled={isLoading}
                          error={incorrectFields.includes("nameShort")}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Teljes név: <NrvText.NrvRequiredMarkText /></th>
                      <th>
                        <NrvForm.NrvTextField
                          maxLength={200}
                          width={700}
                          value={nameFull}
                          onChange={(e) => {
                            setNameFull(e.target.value);
                            removeFromIncorrectFields("nameFull");
                          }}
                          disabled={isLoading}
                          error={incorrectFields.includes("nameFull")}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Adózás: <NrvText.NrvRequiredMarkText /></th>
                      <th>
                        <NrvForm.NrvRadioGroup
                          direction="col"
                          value={taxType}
                          onChange={(e) => {
                            setTaxType(e.target.value);
                            removeFromIncorrectFields("taxNumber");
                          }}
                          items={[{ title: "Adószámmal rendelkezik (Belföldi ÁFA alany)", value: "company" }, { title: "Adószámmal nem rendelkezik (Természetes személy)", value: "nocompany" }]}
                          disabled={isLoading}
                        />
                      </th>
                    </tr>
                    <tr className={isCompany ? "" : "hidden"}>
                      <th>Adószám: {isCompany && <NrvText.NrvRequiredMarkText />}</th>
                      <th>
                        <NrvForm.NrvTextField
                          maxLength={30}
                          width={200}
                          value={taxNumber}
                          onChange={(e) => {
                            setTaxNumber(e.target.value);
                            removeFromIncorrectFields("taxNumber");
                          }}
                          disabled={isLoading}
                          error={incorrectFields.includes("taxNumber")}
                        />
                      </th>
                    </tr>
                    <tr className={isCompany ? "hidden" : ""}>
                      <th>Anyja neve:</th>
                      <th>
                        <NrvForm.NrvTextField maxLength={200} width={500} value={mothersName} valueSet={setMothersName} disabled={isLoading} />
                      </th>
                    </tr>
                    <tr className={isCompany ? "hidden" : ""}>
                      <th>Leánykori név:</th>
                      <th>
                        <NrvForm.NrvTextField maxLength={200} width={500} value={maidenName} valueSet={setMaidenName} disabled={isLoading} />
                      </th>
                    </tr>
                    <tr className={isCompany ? "hidden" : ""}>
                      <th>Születési hely, idő:</th>
                      <th>
                        <NrvForm.NrvTextField maxLength={200} width={320} marginRight={10} value={birthPlace} valueSet={setBirthPlace} disabled={isLoading} />
                        <NrvForm.NrvDatePicker
                          value={birthDate}
                          onChange={(v) => {
                            setBirthDate(v);
                            removeFromIncorrectFields("birthDate");
                          }}
                          disabled={isLoading}
                          highlightToday={false}
                          error={incorrectFields.includes("birthDate")} />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="editModalTitle">
                <NrvIcons.EmailOutline className="form-group-icon" />
                Elsődleges (számlázási) cím
              </div>
              <div className="editModalInputContainer">
                <table className="editModalInputTable" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th style={{ width: 250 }}>Ország: <NrvText.NrvRequiredMarkText /></th>
                      <th>
                        {fetched ? (
                          <NrvForm.NrvSelect
                            width={500}
                            value={countryCode}
                            valueSet={setCountryCode}
                            disabled={isLoading}
                            items={countries.map(country => ({ title: country.name, value: country.code }))}
                          />
                        ) : <NrvForm.NrvSkeleton width={500} />}
                      </th>
                    </tr>
                    <tr>
                      <th>Irányítószám: <NrvText.NrvRequiredMarkText /></th>
                      <th>
                        <NrvForm.NrvTextField
                          maxLength={4}
                          width={200}
                          value={zipCode}
                          onChange={(e) => {
                            setZipCode(e.target.value);
                            removeFromIncorrectFields("zipCode");
                          }}
                          disabled={isLoading}
                          error={incorrectFields.includes("zipCode")}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Település: <NrvText.NrvRequiredMarkText /></th>
                      <th>
                        <NrvForm.NrvTextField
                          maxLength={100}
                          width={500}
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                            removeFromIncorrectFields("city");
                          }}
                          disabled={isLoading}
                          error={incorrectFields.includes("city")}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Cím (utca, házszám, ...): <NrvText.NrvRequiredMarkText /></th>
                      <th>
                        <NrvForm.NrvTextField
                          maxLength={200}
                          width={500}
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                            removeFromIncorrectFields("address");
                          }}
                          disabled={isLoading}
                          error={incorrectFields.includes("address")}
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="editModalTitle">
                <NrvIcons.Phone className="form-group-icon" />
                Központi kapcsolat
              </div>
              <div className="editModalInputContainer">
                <table className="editModalInputTable" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th style={{ width: 250 }}>Telefonszám:</th>
                      <th>
                        <NrvForm.NrvTextField maxLength={20} width={500} value={tel} valueSet={setTel} disabled={isLoading} />
                      </th>
                    </tr>
                    <tr>
                      <th>E-mail cím:</th>
                      <th>
                        <NrvForm.NrvTextField maxLength={50} width={500} value={email} valueSet={setEmail} disabled={isLoading} />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="editModalTitle">
                <NrvIcons.FileAttach className="form-group-icon" />
                Egyéb
              </div>
              <div className="editModalInputContainer">
                <table className="editModalInputTable" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th style={{ width: 250 }}>Megjegyzés:</th>
                      <th>
                        <NrvForm.NrvTextField width={500} multiline={true} rows={5} value={comment} valueSet={setComment} disabled={isLoading} />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
          </div>
        </div>
        <NrvModal.NrvMessageModal
          show={messageModalShow}
          title={messageModalTitle}
          message={messageModalText}
          onClose={handleMessageModalClose}
          variant={messageModalVariant}
        />
      </>
    );
  };

  export default CustomersNewForm;