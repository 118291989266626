import React from "react";
import PropTypes from 'prop-types';

import { TextField, Select, MenuItem, Skeleton, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function NrvTextField(props) {
    return (
        <TextField
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            inputProps={{ maxLength: props.maxLength }}
            variant="outlined"
            size={props.size}
            style={{ width: props.width, marginLeft: props.marginLeft, marginRight: props.marginRight }}
            value={props.value}
            onChange={(e) => {
                if (props.valueSet !== undefined) props.valueSet(e.target.value)
                if (props.onChange !== undefined) props.onChange(e)
            }}
            disabled={props.disabled}
            autoComplete="off"
            error={props.error}
            placeholder={props.placeholder}
            rows={props.rows}
            multiline={props.multiline}
        />
    );
}

NrvTextField.propTypes = {
    /** Place state setter directly, fired on change input */
    valueSet: PropTypes.func,
    /** Fired on change input, return with input object. {(e) => func(e)} */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    /** Height size (MUI): [ small | normal ]; Default: small */
    size: PropTypes.string,
    /** Width size px; Default: 200 */
    width: PropTypes.number,
    /** Max length char; Default: 200 */
    maxLength: PropTypes.number,
    /** Left margin px; Default: 0 */
    marginLeft: PropTypes.number,
    /** Right margin px; Default: 0 */
    marginRight: PropTypes.number,
    /** Disabled: [ true | false ]; Default: false */
    disabled: PropTypes.bool,
    /** Border is red when true: [ true | false ]; Default: false */
    error: PropTypes.bool,
    /** Field placeholder; Default: undefined */
    placeholder: PropTypes.string,
    /** Field rows; Default: undefined */
    rows: PropTypes.number,

    multiline: PropTypes.bool

}

NrvTextField.defaultProps = {
    valueSet: undefined,
    onChange: undefined,
    value: undefined,
    size: "small",
    width: 200,
    maxLength: 200,
    marginLeft: 0,
    marginRight: 0,
    disabled: false,
    error: false,
    placeholder: undefined,
    rows: undefined,
    multiline: undefined
}

function NrvDatePicker(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
            <DatePicker
                margin="normal"
                disableFuture
                openTo="year"
                views={["year", "month", "day"]}
                inputFormat="YYYY.MM.DD."
                value={props.value}
                onChange={(v) => {
                    if (props.valueSet !== undefined) props.valueSet(v)
                    if (props.onChange !== undefined) props.onChange(v)
                }}
                disabled={props.disabled}
                disableHighlightToday={!props.highlightToday}
                renderInput={(params) => {
                    return <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, placeholder: props.placeholder }}
                        sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
                        style={{ width: props.width, marginLeft: props.marginLeft, marginRight: props.marginRight }}
                        size={props.size}
                        variant="outlined"
                        autoComplete="off"
                        error={props.error === undefined ? params.error : props.error}
                    />
                }
                }
            />
        </LocalizationProvider>
    );
}

NrvDatePicker.propTypes = {
    /** Place state setter directly, fired on change input */
    valueSet: PropTypes.func,
    /** Fired on change input, return with input object. {(e) => func(e)} */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.object,
    /** Height size (MUI): [ small | normal ]; Default: small */
    size: PropTypes.string,
    /** Width size px; Default: 170 */
    width: PropTypes.number,
    /** Field placeholder; Default: "ÉÉÉÉ.HH.NN" */
    placeholder: PropTypes.string,
    /** Left margin px; Default: 0 */
    marginLeft: PropTypes.number,
    /** Right margin px; Default: 0 */
    marginRight: PropTypes.number,
    /** Disabled: [ true | false | undefined ]; Default: false */
    disabled: PropTypes.bool,
    /** Border is red when true: [ true | false | undefined ]; Default: undefined */
    error: PropTypes.bool,
    /** HighLight today in calendar: [ true | false | undefined ]; Default: true */
    highlightToday: PropTypes.bool
}

NrvDatePicker.defaultProps = {
    valueSet: undefined,
    onChange: undefined,
    value: undefined,
    size: "small",
    width: 170,
    placeholder: "ÉÉÉÉ.HH.NN",
    marginLeft: 0,
    marginRight: 0,
    disabled: false,
    error: undefined,
    highlightToday: true
}

function NrvSelect(props) {
    return (
        <Select
            sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
            }}
            style={{ width: props.width }}
            value={props.value}
            placeholder={props.placeholder}
            size="small"
            onChange={(e) => {
                if (props.valueSet !== undefined) props.valueSet(e.target.value)
                if (props.onChange !== undefined) props.onChange(e)
            }}
            disabled={props.disabled}
        >
            {props.items.map((item, index) => {
                return (
                    <MenuItem value={item.value} key={index}>
                        {item.title}
                    </MenuItem>
                );
            })}
        </Select>
    );
}

NrvSelect.propTypes = {
    /** Place state setter directly, fired on change input */
    valueSet: PropTypes.func,
    /** Fired on change input, return with input object. {(e) => func(e)} */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.any,
    /** Width size px; Default: 170 */
    width: PropTypes.number,
    /** Select items array | Default: null | { title: "", value: "" } */
    items: PropTypes.array,
    /** Field placeholder; Default: undefined */
    placeholder: PropTypes.string,
    /** Disabled: [ true | false | undefined ]; Default: false */
    disabled: PropTypes.bool
}

NrvSelect.defaultProps = {
    valueSet: undefined,
    onChange: undefined,
    value: undefined,
    width: 170,
    items: undefined,
    placeholder: undefined,
    disabled: false
}

function NrvSkeleton(props) {
    return (
        <Skeleton variant="rounded" width={props.width} height={props.height} />
    )
}

NrvSkeleton.propTypes = {
    /** Width size px; Default: 170 */
    width: PropTypes.number,
    /** Height size px; Default: 40 */
    height: PropTypes.number
}

NrvSkeleton.defaultProps = {
    width: 170,
    height: 40
}

function NrvRadioGroup(props) {
    return (
        <FormControl>
            <RadioGroup
                row={props.direction === "row"}
                value={props.value}
                onChange={(e) => {
                    if (props.valueSet !== undefined) props.valueSet(e.target.value)
                    if (props.onChange !== undefined) props.onChange(e)
                }}
            >
                {props.items.map((item, index) => {
                    return (
                        <FormControlLabel
                            key={index}
                            value={item.value}
                            control={<Radio />}
                            label={item.title}
                            disabled={props.disabled}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    )
}

NrvRadioGroup.propTypes = {
    /** Place state setter directly, fired on change input */
    valueSet: PropTypes.func,
    /** Fired on change input, return with input object. {(e) => func(e)} */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.any,
    /** Radio items array | Default: null | { title: "", value: "" } */
    items: PropTypes.array,
    /** Disabled: [ true | false | undefined ]; Default: false */
    disabled: PropTypes.bool,
    /** Item direction [ row | col | undefined ]; Default: row */
    direction: PropTypes.oneOf(['row', 'col']),
}

NrvRadioGroup.defaultProps = {
    valueSet: undefined,
    onChange: undefined,
    value: undefined,
    items: undefined,
    disabled: false,
    direction: 'row'
}


const NrvForm = {
    NrvDatePicker,
    NrvTextField,
    NrvSelect,
    NrvSkeleton,
    NrvRadioGroup
}

export default NrvForm