import React from "react";

import { Paper, Button } from "@mui/material";
import NrvIcons from "./Icon";
import { textTransform } from "@mui/system";

const NrvPaper = (props) => {
	return (
		<Paper {...props}>
			{props.children}
		</Paper>
	)


}

const NrvErrorContent = (props) => {
	return (
		<div className="content-main">
		<div className="contentErrorContainer">
			
				<span>{props.message}</span>
				<span>{props.detail}</span>
			
		</div>
		</div>
	)
}

const NrvContentTabBar = (props) => {

	return (
		<div className="contentTabBar">
			{props.items.map((item, index) => {
				const classNames = ["item"];
				const active = props.value === item.value
				if (active) classNames.push("active");
				return (
					<div
					key={index}
					className={classNames.join(" ")}
					onClick={() => !active ? props.setValue(item.value) : null}
					>
					<span>{item.name}</span>
					</div>)
			})}
		</div>
	)
}

const NrvContentNoData = (props) => {
	return (
		<div className="contentNoDataContainer">
			{props.title}
			<Button variant="contained" onClick={props.buttonAction} startIcon={<NrvIcons.Close />}>
                {props.buttonTitle}
              </Button>
		</div>
	)
}

const NrvSurface = {
	NrvPaper,
	NrvErrorContent,
	NrvContentTabBar,
	NrvContentNoData
}

export default NrvSurface