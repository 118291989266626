export class ArticleModel {
	constructor(
		id,
		code,
		name,
		active,
		createdAt,
		typeId,
		typeName,
		unitId,
		unitName,
		description
	) {
		this.id = id;
		this.code = code;
		this.name = name;
		this.active = active;
		this.createdAt = createdAt;
		this.typeId = typeId;
		this.typeName = typeName;
		this.unitId = unitId;
		this.unitName = unitName;
		this.description = description;
	}
}