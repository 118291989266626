import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Paper } from "@mui/material";

import NrvIcons from "../../components/Icon";
import NrvFuncBar from "../../components/FuncBar";
import NrvText from "../../components/Text";
import NrvForm from "../../components/Form";
import NrvModal from "../../components/Modal";
import NrvProgress from "../../components/Progress";

const ArticlesNewForm = (props) => {
    const user = useSelector((state) => state.auth);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [isFetched, setIsFetched] = useState(false);

    const [messageModalShow, setMEssageModalShow] = useState(false);
    const [messageModalTitle, setMEssageModalTitle] = useState("");
    const [messageModalText, setMessageModalText] = useState("");
    const [messageModalVariant, setMessageModalVariant] = useState("message");

    const breads = [
        { num: 1, name: "Cikktörzs" },
        { num: 2, name: "Új cikk" },
    ];

    const [incorrectFields, setIncorrectFields] = useState([]);

    const [articleTypes, setArticleTypes] = useState([]);
    const [articleUnits, setArticleUnits] = useState([]);

    const [articleTypeId, setArticleTypeId] = useState("");
    const [articleUnitId, setArticleUnitId] = useState("");
    const [articleName, setArticleName] = useState("");
    const [articleDescription, setArticleDescription] = useState("");
    const [descriptionLeftChars, setDescriptionLeftChars] = useState(500);

    // Handle fetch data from API
    useEffect(() => {
        async function fetchData() {
            const response = await axios.get("/utils/articleData", {
                headers: { Authorization: `Bearer ${user.accessToken}` },
            });
            if (response.data.success) {

                console.log(response.data.data)
                setArticleTypes(response.data.data.articleTypes);
                setArticleUnits(response.data.data.articleUnits);

                setArticleTypeId(response.data.data.articleTypePrimaryId);
                setArticleUnitId(response.data.data.articleUnitPrimaryId);
                setIsFetched(true);
                setIsLoading(false);

            }
        }

        fetchData();
    }, []);

    // Handle change description
    useEffect(() => {
        setDescriptionLeftChars(500 - articleDescription.length);
    }, [articleDescription]);

    // Form validate
    const validateInput = () => {
        setIsLoading(true);
        var errorFields = [];

        if (articleName.length < 5) errorFields.push("articleName");

        if (errorFields.length > 0) {
            handleMessageModalShow(
                "Hibás adatbevitel",
                "Ellenőrizze a piros kerettel jelölt mezőkben megadott adatok helyességét!",
                "error"
            );
            setIncorrectFields(errorFields);
        } else {
            handleSave();
        }
    };

    const removeFromIncorrectFields = (field) => {
        if (incorrectFields.includes(field)) setIncorrectFields(incorrectFields.filter((f) => f !== field));
    };

    // Handle save API
    const handleSave = async () => {
        const data = {
            name: articleName,
            description: articleDescription,
            typeId: articleTypeId,
            unitId: articleUnitId
        };

        const response = await axios.post("/articles", data, {
            headers: { Authorization: `Bearer ${user.accessToken}` },
        });

        if (!response.data.success) {
            handleMessageModalShow(response.data.message, response.data.data, "error");
            return;
        }
        enqueueSnackbar("Új cikk mentés sikeres!", { variant: "success" });
        props.setPage("list");

    };

    // Handle message modal
    const handleMessageModalShow = (title, message, variant) => {
        setMessageModalVariant(variant);
        setMEssageModalTitle(title);
        setMessageModalText(message);
        setMEssageModalShow(true);
        setIsLoading(false);
    };

    const handleMessageModalClose = () => {
        setMEssageModalShow(false);
    };

    // Render
    return (
        <>
            <div className="content">
                <div className="content-top-bar">
                    <NrvFuncBar.NrvFuncBarContainer breads={breads}>
                        <NrvFuncBar.NrvFuncBarButton iconId="close" action={() => props.setPage("list")} color="red" tooltip="Mégsem" />
                        <NrvFuncBar.NrvFuncBarButton iconId="check" action={validateInput} color="green" tooltip="Mentés" disabled={isLoading} />
                    </NrvFuncBar.NrvFuncBarContainer>
                </div>
                <div className="content-main">
                    <Paper className="minw-1000">
                        {isLoading ? (<NrvProgress.NrvLinearProgress />) : null}
                        <div className="editModalTitle">
                            <NrvIcons.Widgets className="form-group-icon" />
                            Új cikk
                        </div>
                        <div className="editModalInputContainer">
                            <table className="editModalInputTable" style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <th style={{ width: 250 }}>
                                            Cikk típus: <NrvText.NrvRequiredMarkText />
                                        </th>
                                        <th>
                                            {isFetched ? (
                                                <NrvForm.NrvSelect
                                                    width={500}
                                                    value={articleTypeId}
                                                    onChange={(e) => setArticleTypeId(e.target.value)}
                                                    disabled={isLoading}
                                                    items={articleTypes.map(type => ({ title: type.name, value: type._id }))}
                                                />
                                            ) : <NrvForm.NrvSkeleton width={500} height={40} />}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Megnevezés: <NrvText.NrvRequiredMarkText />
                                            <br />
                                            (min. 5 karakter)
                                        </th>
                                        <th>
                                            <NrvForm.NrvTextField
                                                width={700}
                                                maxLength={30}
                                                value={articleName}
                                                onChange={(e) => {
                                                    setArticleName(e.target.value);
                                                    removeFromIncorrectFields("articleName");
                                                }}
                                                placeholder="Megnevezés"
                                                disabled={isLoading}
                                                error={incorrectFields.includes("articleName")}
                                            />


                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Leírás:
                                            <br />({descriptionLeftChars} karakter)
                                        </th>
                                        <th>
                                            <NrvForm.NrvTextField
                                                width={700}
                                                maxLength={500}
                                                value={articleDescription}
                                                onChange={(e) => {
                                                    setArticleDescription(e.target.value);
                                                    removeFromIncorrectFields("description");
                                                }}
                                                placeholder="Leírás"
                                                disabled={isLoading}
                                                error={incorrectFields.includes("description")}
                                                multiline
                                                rows={4}
                                            />



                                        </th>
                                    </tr>
                                    <tr>
                                        <th style={{ width: 250 }}>
                                            Nyilvántartási egység: <NrvText.NrvRequiredMarkText />
                                        </th>
                                        <th>
                                            {isFetched ? (
                                                <NrvForm.NrvSelect
                                                    width={300}
                                                    value={articleUnitId}
                                                    onChange={(e) => setArticleUnitId(e.target.value)}
                                                    disabled={isLoading}
                                                    items={articleUnits.map(item => ({ title: item.name, value: item._id }))}
                                                />
                                            ) : <NrvForm.NrvSkeleton width={300} height={40} />}


                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Paper>
                </div>
            </div>
            <NrvModal.NrvMessageModal
                show={messageModalShow}
                title={messageModalTitle}
                message={messageModalText}
                onClose={handleMessageModalClose}
                variant={messageModalVariant}
            />
        </>
    );
};

export default ArticlesNewForm;