import React from 'react';
import "./assets/styles.css"
import "./assets/fonts.css"
import "bootstrap/dist/css/bootstrap.min.css";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import { useSelector } from "react-redux";
import { SnackbarProvider } from 'notistack'

function App() {
  const user = useSelector((state) => state.auth);
  if (!user.authenticated) { return <LoginPage />; }
  return <SnackbarProvider><HomePage /></SnackbarProvider>;
}

export default App;
