import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { api } from "../../controllers/apiController";
import { ArticleModel } from "../../models/Article";

import NrvFuncBar from "../../components/FuncBar";
import NrvProgress from "../../components/Progress";
import NrvSurface from "../../components/Surface";
import NrvIcons from "../../components/Icon";

import ArticleDetail from "./ArticleDetail";
import ArticlePricing from "./ArticlePricing";

const Article = (props) => {

	const user = useSelector((state) => state.auth);

	const articleId = props.data.id;
	const [article, setArticle] = useState(new ArticleModel())
	const [page, setPage] = useState("details");

	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [errorDetail, setErrorDetail] = useState("")

	const [newPricingModalOpen, setNewPricingModalOpen] = useState(false)

	const handleError = (message, detail) => {
		setErrorMessage(message)
		setErrorDetail(detail)
		setError(true)
		setLoading(false)
	}

	// API fetch data
	useEffect(() => {
		const fetch = async () => {
			await api("get", `articles/item/${articleId}`, user.accessToken)
				.catch(err => { handleError("API szerver hiba!", err.message) })
				.then(res => {
					if (res.status !== 200) {
						handleError("API szerver hiba!", res.data)
						return;
					}
					setArticle(prev => Object.assign(prev, res.data))
					setLoading(false)
				})
		}
		fetch()
	}, [])

	const breads = [
		{ num: 1, name: "Cikktörzs" },
		{ num: 2, name: `${article.code} - ${article.name}` }
	];

	// Active page
	const Page = (props) => {
		switch (props.page) {
			case "pricing":
				return <ArticlePricing article={article} newPricingModalOpen={newPricingModalOpen} setNewPricingModalOpen={setNewPricingModalOpen} />
			default:
				return <ArticleDetail article={article} />
		}
	}
   
	// Render
	return (
		<>
			<div className="content">
				{loading ?
					<NrvProgress.NrvLinearProgress /> :
					error ? <NrvSurface.NrvErrorContent message={errorMessage} detail={errorDetail} /> :
						<>
							<div className="content-top-bar">
								<NrvFuncBar.NrvFuncBarContainer breads={breads}>
									<NrvFuncBar.NrvFuncBarButton iconId="back" action={() => props.setPage("list")} tooltip="Vissza" />
									<NrvFuncBar.NrvFuncBarButton
										variant="icon-text"
										text="Cikk adatok"
										iconId="box"
										action={() => setPage("details")}
										tooltip="Cikk adatok"
										active={page === "details" ? true : false}
									/>

									<NrvFuncBar.NrvFuncBarButton
										variant="icon-text"
										text="Árazás"
										iconId="money"
										action={() => setPage("pricing")}
										tooltip="Árazás"
										active={page === "pricing" ? true : false}
									/>
								</NrvFuncBar.NrvFuncBarContainer>
							</div>
							<div className="content-main">
								<Page page={page} />
							</div>
						</>}
			</div>
		</>
	)
};

export default Article;