import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import * as authActions from "../store/actions/auth";
import * as navigationActions from "../store/actions/navigation";

import Dashboard from "../modules/Dashboard";
import Users from "../modules/Users";
import Customers from "../modules/Customers/Customers";
import Articles from "../modules/Articles/Articles";
import Services from "../modules/Services";
import Settings from "../modules/Settings";

import NrvTooltip from "../components/Tooltip";

import { Badge } from "@mui/material";
import {
  MdMenu,
  MdMenuOpen,
  MdOutlinePowerSettingsNew,
  MdPerson,
  MdDashboard,
  MdPeople,
  MdWidgets,
  MdDateRange,
  MdSettings
} from "react-icons/md";

export default function HomePage() {
  //const socket = io.connect();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [socketState, setSocketState] = useState(false);

  // Socket Snackbar messages
  /*
  socket.on("loggedIn", (data) => {
    if (data.userid !== user.userId) {
      enqueueSnackbar(data.displayname + " bejelentkezett.", { variant: "info" });
    }
  });

  // Socket state
  socket.on("connect", (data) => {
    setSocketState(true);
  });
  socket.on("disconnect", (data) => {
    setSocketState(false);
  });

  // Socket connect
  useEffect(() => {
    socket.on("connect", function (data) {
      socket.broadcast.emit("login", { userId: user.userId, accessToken: user.accessToken });
    });
    dispatch(navigationActions.resetMenu());
    enqueueSnackbar("Belépve: " + user.displayName, { variant: "success" });
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  // Render
  return (
    <>
      <Header socketState={socketState} />
      <Sidebar />
      <Module />
    </>
  );
}

function Header(props) {
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  // Handle Logout
  const handleLogout = () => {
    dispatch(authActions.logOut());
  };

  // Handle sidebar Show / Hide
  const handleToggleMenu = () => {
    var r = document.querySelector(":root");
    if (sidebarOpen) {
      r.style.setProperty("--sidebar-left", "-220px");
      setSidebarOpen(false);
    } else {
      r.style.setProperty("--sidebar-left", "0px");
      setSidebarOpen(true);
    }
  };

  // Render
  return (
    <header>
      <div className="groupLeft">
        <NrvTooltip.NrvLightTooltip title={sidebarOpen ? "Menü elrejtése" : "Menü megjelenítése"} placement="bottom-end">
          <div className="headerButton" onClick={handleToggleMenu}>
            {sidebarOpen ? <MdMenuOpen size={30} /> : <MdMenu size={30} />}
          </div>
        </NrvTooltip.NrvLightTooltip>
      </div>
      <div className="groupRight">
        <div className="headerProfile">
          <div className="leftBox">
          {/* 
            <Badge
              sx={{
                "& .MuiBadge-badge": {
                  right: `2px`,
                  top: `3px`,
                },
              }}
              color={props.socketState ? "success" : "error"}
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
            
              
            </Badge>
            */}
            <MdPerson size={30} />
          </div>
          <div className="centerBox">
            <div className="nameContainer">{user.displayName}</div>
            <div className="usernameContainer">{user.userName}</div>
          </div>
          <NrvTooltip.NrvLightTooltip title="Kijelentkezés" placement="bottom-start">
            <div className="rightBox" onClick={handleLogout}>
              <MdOutlinePowerSettingsNew size={30} />
            </div>
          </NrvTooltip.NrvLightTooltip>
        </div>
      </div>
    </header>
  );
}

function Sidebar() {
  const user = useSelector((state) => state.auth);
  const activeMenuName = useSelector((state) => state.navigation.activeMenuName);

  const [groupsOpened, setGroupsOpened] = useState([]);

  // Menu item
  const SidebarMenuItem = (props) => {
    const dispatch = useDispatch();

    // Handle change menu
    const changeMenu = () => {
      if (props.group) {
        if (groupsOpened.includes(props.menuName)) {
          return setGroupsOpened(groupsOpened.filter((item) => item !== props.menuName));
        } else {
          return setGroupsOpened((prevState) => [...prevState, props.menuName]);
        }
      }
      return dispatch(navigationActions.changeMenu(props.menuName));
    };

    // Handle active / inactive menu item
    const classNames = ["sidebarMenuItem"];
    if (props.active) {
      classNames.push("active");
    } else {
      classNames.push("inactive");
    }

    const classNamesChev = ["sidebarMenuIconGroupChevron"];

    if (groupsOpened.includes(props.menuName)) {
      classNamesChev.push("active");
    }

    // Menu item icon
    const MenuItemIcon = (props) => {
      if (props.menuName === "dashboard") return <MdDashboard size={30} color={props.active ? "white" : "#10355a"} />;
      if (props.menuName === "customers") return <MdPeople size={30} color={props.active ? "white" : "#10355a"} />;
      if (props.menuName === "articles") return <MdWidgets size={30} color={props.active ? "white" : "#10355a"} />;
      if (props.menuName === "services") return <MdDateRange size={30} color={props.active ? "white" : "#10355a"} />;
      if (props.menuName === "users") return <MdPerson size={30} color={props.active ? "white" : "#10355a"} />;
      if (props.menuName === "settings") return <MdSettings size={30} color={props.active ? "white" : "#10355a"} />;
      return null;
    };

    // Render
    return (
      <>
        <div className={classNames.join(" ")} onClick={changeMenu}>
          {<MenuItemIcon menuName={props.menuName} active={props.active} />}
          <span>{props.displayName}</span>
        </div>
      </>
    );
  };

  // Render
  return (
    <aside id="menu" className="sidebar">
      <div className="sidebar-top">Főmenü</div>
      <div className="sidebar-main">
        <SidebarMenuItem
          displayName="Vezérlőpult"
          menuName="dashboard"
          active={activeMenuName === "dashboard" ? true : false}
        />
        <SidebarMenuItem
          displayName="Ügyféltörzs"
          menuName="customers"
          active={activeMenuName === "customers" ? true : false}
        />

        {/* 
        <SidebarMenuItem
          displayName="Cikktörzs"
          menuName="articles"
          active={activeMenuName === "articles" ? true : false}
        />
        <SidebarMenuItem
          displayName="Szolgáltatás"
          menuName="services"
          active={activeMenuName === "services" ? true : false}
        />
        */}

        
        {user.isAdmin && (
          <SidebarMenuItem
            displayName="Felhasználók"
            menuName="users"
            active={activeMenuName === "users" ? true : false}
          />
        )}
        

        {/* 
        {user.isAdmin && (
          <SidebarMenuItem
            displayName="Beállítások"
            menuName="settings"
            active={activeMenuName === "settings" ? true : false}
          />
        )}
        */}
        
        
      </div>
    </aside>
  );
}

function Module() {
  const activeMenuName = useSelector((state) => state.navigation.activeMenuName);

  if (activeMenuName === "dashboard") return <Dashboard />;
  if (activeMenuName === "customers") return <Customers />;
  if (activeMenuName === "articles") return <Articles />;
  if (activeMenuName === "services") return <Services />;
  if (activeMenuName === "users") return <Users />;
  if (activeMenuName === "settings") return <Settings />;
}