import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//import store from "./store/store";
import { Provider } from "react-redux";
import { legacy_createStore as createStore, combineReducers, applyMiddleware, } from "redux";
import ReduxThunk from "redux-thunk";
import authReducer from './store/reducers/auth'
import navigationReducer from './store/reducers/navigation'

const rootReducer = combineReducers({ auth: authReducer, navigation: navigationReducer });
export const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
      <App />
    </Provider>
);