import { LOGIN, LOGOUT, UPDATE, REFRESH } from "../actions/auth";

const initialState = {
  authenticated: false,
  userId: null,
  userName: null,
  displayName: null,
  accessToken: null,
  refreshToken: null,
  isAdmin: null,
};

const actions = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        authenticated: true,
        userId: action.userData.userId,
        userName: action.userData.userName,
        displayName: action.userData.displayName,
        accessToken: action.userData.accessToken,
        refreshToken: null,
        isAdmin: action.userData.isAdmin,
      };
    case LOGOUT:
      return initialState;
    case UPDATE:
      return {
        ...state,
        userName: action.data.username,
        displayName: action.data.name,
      };
    case REFRESH:
      return {
        ...state,
        accessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken,
      };

    default:
      return state;
  }
};

export default actions;
