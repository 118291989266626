import React, { useState } from "react";

import ArticlesNewForm from "./ArticlesNewForm";
import ArticlesList from "./ArticlesList";
import Article from "./Article";

export default function Articles(props) {
  const [page, setPage] = useState("list");
  const [pageData, setPageData] = useState();

  if (page === "new") return <ArticlesNewForm setPage={setPage} />;
  if (page === "details") return <Article setPage={setPage} data={pageData} setData={setPageData} />;
  if (page === "list") return <ArticlesList setPage={setPage} data={pageData} setData={setPageData} />;
  return null;
}