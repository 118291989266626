import { CHANGE_MENU, RESET_MENU } from "../actions/navigation";

const initialState = {
  activeMenuName: "dashboard",
};

const actions = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MENU:
      return {
        activeMenuName: action.menuName,
      };
    case RESET_MENU:
      return initialState;
    default:
      return state;
  }
};

export default actions
