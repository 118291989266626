import React from "react";

import NrvTooltip from "./Tooltip";

const NrvRequiredMarkText = () => {
    return (
        <NrvTooltip.NrvLightTooltip title="Kötelezően kitöltendő mező.">
            <span className="strong-red-text cursor-help" style={{ marginLeft: 5 }}>
                *
            </span>
        </NrvTooltip.NrvLightTooltip>
    )
}

const NrvText = {
    NrvRequiredMarkText
}

export default NrvText